@font-face {
  font-family: "Gilroy";
  src: url("../../public/Fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Black";
  src: url("../../public/Fonts/Gilroy-Black.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../../public/Fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("../../public/Fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
}
@media (min-width:1200px){
.container{
  width: 1170px !important;
}
}

.heading {
  width: 473px;
  height: 44px;
  left: 135px;
  top: 160px;
  font-family: "Gilroy-Black";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
  color: #141414;
}


.button-row {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: nowrap; /* Ensure items stay in a single row */
  gap: 14px;
  overflow-x: auto;
  overflow-y: hidden; /* Hide vertical scrollbar */
  margin: 0 auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
  white-space: nowrap; /* Ensure items don't wrap to the next line */
}

.button-row::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers (e.g., Chrome, Safari) */
}



/* .button-row {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
  overflow-x: auto; 
  overflow-y: scroll; 
  margin: 0 auto; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  cursor: pointer; 
}

.button-row::-webkit-scrollbar {
  display: none;  
} */
.margin-top {
  margin-top: 150px;
}

.buttonNav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px 29px;
  gap: 10px;
  height: 50px;
  background: #f6f6f6;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #141414;
}


.button-primary {
  background: #cb0064;
  color: #ffffff;
}

/* YourComponentStyles.css */

/* Main Container */
.main-container {
  width: 490px;
  height: 306px;
  flex-shrink: 0;
  border: 1px solid #f8f8f8;
  background: #f6f6f6;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
}

/* Most Popular Button */
.most-popular-button {
  display: flex;
  padding: 8px 13px 7px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: linear-gradient(90deg, #cb0064 0%, #9300b8 100%);
  color: white; /* Assuming white text color for better contrast */
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

/* Content Container */
.content-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.first-icon {
  gap: 20px;
}

.graphic-heading {
  color: #141414;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy-Black";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.qMark {
  color: #d93870;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy-Black";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* Text Container */
.text-container {
  color: #4f4f4f;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
}
.logo-txt {
  color: #141414;
  font-family: "Gilroy-SemiBold";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Discount Text */
.discount-text {
  background: linear-gradient(90deg, #cb0064 0%, #9300b8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-radius: 2px;
  border: 1px solid #cb0064;
  font-family: "Gilroy-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.5%; /* 16.03px */
  padding: 3px 15px;
  margin-left: 10px;
}

/* Benefits List */
.benefits-list {
  list-style-type: none;
  padding: 0;
  color: #4f4f4f;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.benefits-list li {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust gap as needed */
  color: #4f4f4f;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Top Layer Styles */
.top-layer {
  width: 550px;
  height: 700px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}
/* .button {
  min-width: 150px;
} */

/* Section Heading Styles */
.section-heading {
  color: #141414;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px; /* Adjust margin as needed */
}

/* Section Paragraph Styles */
.section-paragraph {
  color: #141414;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img-parent {
  width: 100%;
  height: 500px;
  
}

@media (max-width: 768px) {

  .container{
overflow: hidden !important;
  }
  .graphic-heading,
  .qMark {
    font-size: 22px;
  }

  .button-row {
    gap: 10px;
  }

  .buttonNav {
    min-width: 200px;
  }
  .top-layer {
    min-width: 500px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100%;
    margin: 0%;
    padding: 10px;
  }
  .img {
    width: 300px;
    height: 300px;
    margin: 0%;
    padding: 0%;
  }
  .heading {
    width: 100%;
    height: 100%;
    left: 5px;
    top: 10px;
    font-family: "Gilroy-Black";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    color: #141414;
  }
  .responsive-section {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0%;
    margin: 0%;
    padding: "20px";
    display: "flex";
    align-items: flex-start;
    gap: "0px";
  }
  .rsp {
    width: 100%;
    height: 100%;
    display: flex
  }
  .img-parent {
    width: 100%;
    height: 100%;
    /* margin-top: 250px; */
  }
  .respon{
    width: 100%;
  }
  
  .button-row {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: nowrap; /* Ensure items stay in a single row */
    gap: 14px;
    overflow-x: auto;
    overflow-y: hidden; /* Hide vertical scrollbar */
    margin: 0 auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    cursor: pointer;
    white-space: nowrap; /* Ensure items don't wrap to the next line */
  }
  .right-fourth{
    padding: 0%;
    margin: 0%;
  }
}

@media (max-width: 480px) {
  .main-container {
    width: 320px;
    height: 306px;
    flex-shrink: 0;
    border: 1px solid #f8f8f8;
    background: #f6f6f6;
    padding: 20px; /* Adjust padding as needed */
    box-sizing: border-box;
  }
  .right-fourth{
    padding: 0%;
    margin: 0%;
  }
  .button-row {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: nowrap; /* Ensure items stay in a single row */
    gap: 14px;
    overflow-x: auto;
    overflow-y: hidden; /* Hide vertical scrollbar */
    margin: 0 auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    cursor: pointer;
    white-space: nowrap; /* Ensure items don't wrap to the next line */
  }
  .responsive-section {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0%;
    margin: 0%;
    padding: "20px";
    display: "flex";
    align-items: flex-start;
    gap: "0px";
  }
  .top-layer {
    width: 320px;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100%;
    margin: 0%;
    padding: 10px;
  
  }
  
}

.paraHeading {
  color: #141414;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Gilroy-Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.section-paragraph {
  color: #414141;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
