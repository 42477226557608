@import url("https://fonts.googleapis.com/css2?family=Gilroy-SemiBold:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy-Bold:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy-Medium:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Coiny:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Eagle+Lake:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy-Regular:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+MT:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georgia:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sans+Serif+Collection:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mont:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arial:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lily+Script+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lucida+Handwriting:ital,wght@1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Brush+Script+MT:ital,wght@1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cooper+Black:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Papyrus:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Broadway:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mont-SemiBold:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy-Black:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Averta+Demo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=SF+Pro+Text:wght@600&display=swap");
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');
@import url('https://fonts.cdnfonts.com/css/gilroy-black');
html {
  font-size: 16px;
}
body {
  margin: 0;
  line-height: normal;
  overflow-x: hidden;

    /* width: 90rem; */
}
:root {
  /* fonts */
  --font-gilroy-bold: Gilroy-Bold;
  --font-gilroy-regular: Gilroy-Regular;
  --font-syncopate: Syncopate;
  --font-gilroy-semibold: Gilroy-SemiBold;
  --font-gilroy-medium: Gilroy-Medium;
  --paragraph-semibold-body-16: Poppins;
  --font-coiny: Coiny;
  --font-eagle-lake: "Eagle Lake";
  --font-inter: Inter;
  --text-single-100-bold: "DM Sans";
  --font-manrope: Manrope;
  --font-arial: Arial;
  --font-times-new-roman: "Times New Roman";
  --font-mont-semibold: Mont-SemiBold;
  --font-sans-serif-collection: "Sans Serif Collection";
  --font-broadway: Broadway;
  --font-papyrus: Papyrus;
  --font-cooper-black: "Cooper Black";
  --font-lucida-handwriting: "Lucida Handwriting";
  --font-brush-script-mt: "Brush Script MT";
  --font-lily-script-one: "Lily Script One";
  --font-mont: Mont;
  --font-georgia: Georgia;
  --font-bodoni-mt: "Bodoni MT";
  --font-gilroy-black: Gilroy-Black;
  --font-raleway: Raleway;
  --font-ubuntu: Ubuntu;
  --body-body-1-semibold: Mulish;
  --font-sf-pro-text: "SF Pro Text";
  --font-averta-demo: "Averta Demo";

  /* font sizes */
  --font-size-sm: 0.875rem;
  --text-single-100-bold-size: 1rem;
  --font-size-lg-1: 1.131rem;
  --font-size-xs: 0.75rem;
  --body-default-size: 1.125rem;
  --font-size-5xl: 1.5rem;
  --font-size-17xl: 2.25rem;
  --font-size-mini: 0.938rem;
  --font-size-3xs: 0.625rem;
  --font-size-29xl: 3rem;
  --font-size-base-9: 1.056rem;
  --font-size-35xl: 3.375rem;
  --font-size-mid: 1.063rem;
  --font-size-xl: 1.25rem;
  --font-size-16xl: 2.188rem;
  --font-size-sm-2: 0.825rem;
  --font-size-10xl: 1.813rem;
  --font-size-smi: 0.813rem;

  /* Colors */
  --base-white: #fff;
  --color-dimgray-100: #696969;
  --text-gray: #62646a;
  --color-dimgray-200: #535353;
  --color-dimgray-300: #505050;
  --color-dimgray-400: #4c4c4c;
  --color-mediumvioletred: #cb0064;
  --color-black: #000;
  --color-hotpink-100: #d93870;
  --color-hotpink-200: rgba(217, 56, 112, 0.11);
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #e3e2e2;
  --color-gainsboro-300: #dcdcdc;
  --color-gainsboro-400: #d9d9d9;
  --color-darkslategray-100: #434343;
  --color-darkslategray-200: #2b3858;
  --dark-gray: #383636;
  --color-indianred: #ef5363;
  --color-gray-100: #8f8f8f;
  --color-gray-200: #878787;
  --text-body: #808080;
  --color-gray-300: #222325;
  --charcoal-black: #232323;
  --color-gray-400: #1c1c1c;
  --color-gray-500: #141414;
  --color-gray-600: rgba(20, 20, 20, 0.6);
  --color-darkgray-100: #b3b3b3;
  --color-whitesmoke-100: #f8f8f8;
  --color-whitesmoke-200: #f6f0f0;
  --color-whitesmoke-300: #eee;
  --neutral-colors-headings-black: #5d5a88;
  --light-text-color-body-1: #92929d;
  --light-primary-color-50: #5541d7;
  --color-aliceblue: #eff6fa;

  /* Gaps */
  --gap-base: 1rem;
  --gap-5xl: 1.5rem;
  --gap-7xs: 0.375rem;
  --gap-8xs: 0.312rem;
  --gap-3xs: 0.625rem;
  --gap-5xs: 0.5rem;
  --gap-mid-5: 1.093rem;
  --gap-9xs: 0.25rem;
  --gap-91xl: 6.875rem;
  --gap-5xs-5: 0.468rem;
  --gap-31xl: 3.125rem;
  --gap-xl: 1.25rem;
  --gap-xs: 0.75rem;
  --gap-77xl: 6rem;
  --gap-sm: 0.875rem;
  --gap-13xl: 2rem;
  --gap-mid: 1.062rem;
  --gap-23xl: 2.625rem;
  --gap-15xl: 2.125rem;
  --gap-lg: 1.125rem;
  --gap-8xl: 1.687rem;

  /* Paddings */
  --padding-xs: 0.75rem;
  --padding-5xl: 1.5rem;
  --padding-xl: 1.25rem;
  --padding-17xl: 2.25rem;
  --padding-15xl: 2.125rem;
  --padding-base: 1rem;
  --padding-4xl: 1.437rem;
  --padding-10xl: 1.812rem;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-3xs: 10px;
  --br-xs: 12px;
  --br-8xs: 5px;
  --br-9xs: 4px;
  --br-base-1: 15.1px;
  --br-81xl: 100px;
  --br-11xs-3: 1.3px;
  --br-10xs-7: 2.7px;
  --br-11xl-5: 30.5px;
  --br-4xs: 9px;
  --br-3xs-1: 9.1px;
  --br-7xs: 6px;
}
