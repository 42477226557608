.sliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin:50px auto 40px;
}

.cardCarousel {
  display: flex;
  justify-content: flex-start;
  transition: transform 0.5s ease-in-out;
  /* width: calc(251px * 5); */
  width: calc(236px * 5);
  padding-left: 0;
  margin-left: -0.4rem;
}

/* @media(min-width:1170px){
  .sliderContainer{
    overflow: visible !important;
  }
} */

.cardContainer {
  box-sizing: border-box;
  width: 17.25rem;
  height: 19.5rem;
  border: 1px solid #f6f0f0;
  border-radius:0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 16px;
  margin: 10px;
  z-index: 1; 
  background: white;
}

.cardContainer::before {
  content: '';
  position: absolute;
  width: 17.25rem;
  height: 19.5rem;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.625rem; /* Match the border radius of the card */
  background: linear-gradient(135deg, rgba(203, 0, 100, 1), rgba(147, 0, 184, 1));
  z-index: -1; /* Ensure it sits below the card content */
  opacity: 0;
  transition: opacity 900ms ease-in-out;
 
}

.cardContainer:hover::before,
.cardContainer.active::before {
  opacity: 1;
  
}


.content {
  /* height: 112.5rem; */
  text-align: center;
  background-color: #f8f8f8;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;
    border-radius: 0.625rem;
    margin-bottom: -9px;
    z-index: 2;
}

.imageContainer {
  width: 274px;
  height: 143px;
  background: #f8f8f8;
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding-top: 20px;
}

.imageContainer img {
  max-width: 100%;
  height: auto;
}

.caption {
  font-family: 'Gilroy-Bold',sans-serif;
color: #141414;
/* font-family: Gilroy-Bold; */
font-size: 1.0625rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.divider {
  width: 252px;
  height: 0px;
  border: 1px solid #f6f0f0;
  margin: 10px 0;
}

.description {
  color: #141414;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 29.86px;

}

/* .icon {
  width: 65.7px;
  height: 29.86px;
} */

/* .icon img {
  width: 68.09px;
  height: 29.86px;

} */


.backButton {
  background-color: transparent;
  border: none;
  color: #141414;
  font-family: "Poppins";
  font-size: 15px;
  cursor: pointer;
  padding: 5px 10px;
}

.backButton:focus {
  border: 1px solid #141414;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  padding: 0px 20px;
}

.sliderButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 6px;
  width: 101px;
  height: 41px;
  background: linear-gradient(88.23deg, #9300b8, #cb0064,#cb0064, #9300b8);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-size: 300%;
  background-position: left;
  transition: 500ms background-position ease-in-out;
}

.sliderButton:hover {
  background-position: right;
}

.sliderButton .buttonText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 115%;
  color: #ffffff;
  margin-right: 6px;
}

.arrowRight {
  position: relative;
  width: 14px;
  height: 14px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
}

.sliderButton .sliderButton {
  content: "";
  position: absolute;
  background: #ffffff;
  border-radius: 1px;
}

.sliderButton .arrowRight::before {
  width: 10.5px;
  height: 1.5px;
  top: 6.5px;
  left: 2px;
  transform: rotate(45deg);
}

.sliderButton .arrowRight::after {
  width: 10.5px;
  height: 1.5px;
  top: 6.5px;
  left: 2px;
  transform: rotate(-45deg);
}


.selectionIcon{
  width: 1.5625rem;
  height: 1.5625rem;
  position: absolute;
  top: 0.63rem;
  right: 0.62rem;
  display: none;
  
}


.selectedIcon{
  width: 1.5625rem;
height: 1.5625rem;
position: absolute;
top: 0.63rem;
right: 0.62rem;
display: none;

}

.visible {
  display: block; 
}

.container{
  padding-left:0 !important;
}

@media (max-width:768px) {
  .cardCarousel{
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: none !important;
    transition: none !important;
    gap:2.62rem;
  }
  
  
}


@media (min-width: 768px) and (max-width: 992px) {
.cardCarousel{
  width: 100%;
}

}

@media (min-width: 992px) and (max-width: 1230px) {
  .cardCarousel{
    width: 100%;
  }
  
  }

/* @media (max-width: 575px) {
  .cardContainer{
    
   width: 100%;
  }
  .sliderContainer{
    width:100%;
  }
  .cardCarousel{
    width:100%;
  }
  } */