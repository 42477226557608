.imageContainer,
.imagePlaceholder {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
}
.imageContainer {
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: var(--color-whitesmoke-300);
}
.imagePlaceholder {
  height: 35.9%;
  top: 9.49%;
  bottom: 54.61%;
  display: none;
}
.freddieDeckow {
  position: relative;
  font-weight: 800;
}
.leadMarketingAdministrator {
  position: relative;
  font-size: var(--font-size-3xs);
  color: var(--color-dimgray-300);
}
.namejob {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.textTestimonial {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-size);
  line-height: 1.875rem;
  font-family: var(--text-single-100-bold);
  color: var(--color-gray-400);
  text-align: center;
}
.namejobParent {
  align-self: stretch;
  background-color: var(--base-white);
  height: 29.844rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--padding-xl) var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-mid-5);
  z-index: 0;
}
.logo5ff3c18e1Icon,
.profilPictureChild {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.profilPictureChild {
  width: 18.688rem;
  height: 25.781rem;
  z-index: 0;
}
.logo5ff3c18e1Icon {
  width: 6.194rem;
  height: 1.525rem;
}
.starsChild {
  width: 1.275rem;
  position: relative;
  height: 1.275rem;
}
.stars,
.starsTestimonialLogo {
  display: flex;
  align-items: center;
}
.stars {
  filter: drop-shadow(0 34.2px 72.74px rgba(21, 21, 21, 0.15));
  flex-direction: row;
  justify-content: flex-start;
}
.starsTestimonialLogo {
  width: 18.563rem;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  z-index: 1;
}
.frameContainer,
.frameGroup,
.frameParent,
.profilPicture {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.profilPicture {
  margin: 0 !important;
  top: 0;
  left: calc(50% - 149px);
  display: flex;
  gap: var(--gap-8xs);
  z-index: 1;
}
.frameContainer,
.frameGroup,
.frameParent {
  top: 127.75rem;
  left: 98.375rem;
  box-shadow: 20px 24px 48px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  width: 18.625rem;
  overflow: hidden;
  display: none;
  font-family: var(--font-manrope);
}
.frameContainer,
.frameGroup {
  left: 118.625rem;
}
.frameContainer {
  left: 138.875rem;
}
.container {
  position: absolute;
  top: -24.494rem;
  left: 17.563rem;
  background-color: var(--base-white);
  width: 90rem;
  height: 7.369rem;
}
.company,
.customer {
  position: absolute;
  top: 7.5rem;
  left: 32.563rem;
  font-size: var(--font-size-mid);
  line-height: 1.5rem;
  font-weight: 600;
}
.customer {
  left: 46.813rem;
}
.enterYourEmail,
.subscribeToNewsletter {
  position: absolute;
  left: 60.5rem;
  line-height: 1.5rem;
}
.subscribeToNewsletter {
  top: 7.5rem;
  font-size: var(--body-default-size);
  font-family: var(--font-inter);
}
.enterYourEmail {
  top: 11rem;
  display: inline-block;
  width: 16.875rem;
}
.aboutCompany {
  position: relative;
  line-height: 1.25rem;
}
.links,
.links1 {
  position: absolute;
  top: 11rem;
  left: 32.563rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
  color: var(--color-darkgray-100);
}
.links1 {
  left: 46.813rem;
}
.inputChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--base-white);
  box-sizing: border-box;
  width: 16.875rem;
  height: 3rem;
}
.enterYourEmail1 {
  position: absolute;
  top: 0.688rem;
  left: 1rem;
  line-height: 1.625rem;
  font-weight: 300;
}
.input {
  top: 0;
  left: 0;
  width: 16.875rem;
}
.buttonIcon,
.input,
.newsletterForm {
  position: absolute;
  height: 3rem;
}
.buttonIcon {
  top: 0;
  left: 13.875rem;
  width: 3rem;
}
.newsletterForm {
  top: 14.75rem;
  left: 60.5rem;
  width: 16.875rem;
  font-size: var(--font-size-xs);
  color: var(--color-darkgray-100);
}
.copyright2023 {
  position: absolute;
  top: 19.75rem;
  left: 60.5rem;
  line-height: 1.5rem;
  font-weight: 300;
}
.logo {
  margin: 0;
}
.logoMaker {
  position: absolute;
  top: 0.181rem;
  left: 2.338rem;
  line-height: 0.896rem;
  display: inline-block;
  width: 4.394rem;
  height: 1.825rem;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.025rem;
  height: 2.031rem;
}
.logoMakerParent {
  width: 6.731rem;
  position: relative;
  height: 2.031rem;
}
.loremIpsumDolor {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.625rem;
  display: inline-block;
  width: 16.875rem;
}
.facebookIcon,
.instagramIcon,
.linkedinIcon,
.twitterIcon {
  position: absolute;
  height: 95%;
  width: 12.17%;
  top: 5%;
  right: 0.21%;
  bottom: 0;
  left: 87.62%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.facebookIcon,
.instagramIcon,
.twitterIcon {
  height: 93.33%;
  width: 12.03%;
  right: 31.33%;
  bottom: 1.67%;
  left: 56.64%;
}
.facebookIcon,
.twitterIcon {
  height: 73.89%;
  width: 12.17%;
  top: 10.56%;
  right: 62.31%;
  bottom: 15.56%;
  left: 25.52%;
}
.facebookIcon {
  height: 95%;
  width: 6.78%;
  top: 0;
  right: 93.43%;
  bottom: 5%;
  left: -0.21%;
}
.socialLinks {
  position: absolute;
  top: 8rem;
  left: 0.25rem;
  width: 8.938rem;
  height: 1.125rem;
}
.loremIpsumDolorAmetConsectParent {
  width: 16.875rem;
  position: relative;
  height: 9.125rem;
  font-size: var(--font-size-sm);
  color: var(--color-darkgray-100);
  font-family: var(--paragraph-semibold-body-16);
}
.groupParent {
  position: absolute;
  top: 7.5rem;
  left: 11.581rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  font-size: 0.894rem;
  font-family: var(--font-syncopate);
}
.footerStyle4 {
  position: absolute;
  bottom: 0;
  left: calc(50% - 720px);
  background-color: var(--color-gray-500);
  width: 90rem;
  height: 26.375rem;
  overflow: hidden;
  color: var(--base-white);
}
.logoMaker1 {
  position: absolute;
  top: 0.213rem;
  left: 2.731rem;
  line-height: 1.048rem;
  display: inline-block;
  width: 5.144rem;
  height: 2.138rem;
}
.groupItem,
.logoMakerGroup {
  position: absolute;
  height: 2.375rem;
}
.groupItem {
  top: 0;
  left: 0;
  width: 2.369rem;
}
.logoMakerGroup {
  top: 2.25rem;
  left: 8.375rem;
  width: 7.875rem;
  font-size: 1.05rem;
  color: var(--color-dimgray-200);
  font-family: var(--font-syncopate);
}
.graphicDesignServices,
.home {
  width: 3.063rem;
  position: relative;
  line-height: 115%;
  display: inline-block;
  flex-shrink: 0;
}
.graphicDesignServices {
  width: 10.625rem;
  height: 1.125rem;
}
.formDownarrow1Icon {
  width: 0.938rem;
  position: relative;
  height: 0.938rem;
  overflow: hidden;
  flex-shrink: 0;
}
.dropdownMenu {
  width: 11.875rem;
  height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.portfolio {
  text-transform: capitalize;
}
.aboutUs,
.portfolio {
  position: relative;
  line-height: 115%;
}
.homeParent {
  position: absolute;
  top: 2.813rem;
  left: calc(50% - 314px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.75rem;
  color: var(--color-darkslategray-100);
}
.div {
  position: absolute;
  top: 2.84%;
  left: 70.56%;
  line-height: 115%;
  font-weight: 500;
  color: var(--color-gray-500);
  text-align: center;
}
.user11 {
  width: 1.625rem;
  position: relative;
  height: 1.625rem;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  width: 0.063rem;
  position: relative;
  border-right: 1px solid #e6dde8;
  box-sizing: border-box;
  height: 1.938rem;
}
.groupInner {
  border-radius: var(--br-7xs);
  background: linear-gradient(90deg, #cb0064, #9300b8);
  border: 1px solid var(--color-mediumvioletred);
  box-sizing: border-box;
}
.groupInner,
.rectangleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 7rem;
  height: 2.313rem;
}
.buttonText {
  position: absolute;
  top: 0.625rem;
  left: 1rem;
  line-height: 115%;
  font-weight: 500;
}
.groupContainer {
  width: 7rem;
  position: relative;
  height: 2.313rem;
}
.user11Parent {
  position: absolute;
  top: 2.313rem;
  left: 71.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
  text-align: center;
  color: var(--base-white);
}

.paragraph {
  position: relative;
}
.chooseALogo{
  font-family: 'gilroybold' !important;
  color: #141414;
font-feature-settings: 'clig' off, 'liga' off;

font-size: 2.1875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.paragraph {
color: #141414;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.chooseALogoToEditOrSaveParent {
  position: relative;
  /* top: 9.125rem;
  left: 8.438rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-16xl);
  color: var(--color-gray-500);
  font-family: var(--font-gilroy-bold);
}
.icon {
  position: relative;
  /* top: calc(50% - 49px);
  left: calc(50% - 71px); */
  /* width: 8.938rem;
  height: 6.181rem; */
  object-fit: cover;
  display: flex;
width: 9rem;
height: auto;
/* padding: 5.0625rem 2.5rem 5.10625rem 2.5625rem; */
justify-content: center;
align-items: center;
}
.component20 {
  width: 17rem;
  position: relative;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-whitesmoke-300);
  box-sizing: border-box;
  height: 15.875rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; 
  background: white;
  transition: border-color 0.3s ease-in-out; /* Add transition for border color change */
  padding-top: 1px;
  padding-bottom: 1px;
}

.component20::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: var(--br-5xs); /* Match the border radius of the component */
  background: linear-gradient(135deg, rgba(203, 0, 100, 1), rgba(147, 0, 184, 1));
  z-index: -1;
  opacity: 0;
  transition: opacity 900ms ease-in-out;
 
}

.component20:hover::before,
.component20.active::before {
  opacity: 1;
}

.content {
  /* height: 112.5rem; */
  text-align: center;
  background-color: white;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 99%;
    height: 100%;
    border-radius: 6px;
   gap: 1.8rem;
    z-index: 2;
}



.icon1,
.icon2,
.icon3 {
  position: absolute;
  top: calc(50% - 54px);
  left: calc(50% - 67px);
  width: 8.438rem;
  height: 6.731rem;
  object-fit: cover;
}
.icon2,
.icon3 {
  top: calc(50% - 46px);
  left: calc(50% - 95px);
  width: 11.938rem;
  height: 5.706rem;
}
.icon3 {
  top: calc(50% - 51px);
  left: calc(50% - 72px);
  width: 9.063rem;
  height: 6.413rem;
}
.component20Parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xl);
  /* flex-wrap: wrap; */
}
.icon4,
.icon5,
.icon6 {
  position: absolute;
  top: calc(50% - 57px);
  left: calc(50% - 72px);
  width: 9.063rem;
  height: 7.063rem;
  object-fit: cover;
}
.icon5,
.icon6 {
  top: calc(50% - 47px);
  left: calc(50% - 73px);
  width: 9.125rem;
  height: 5.875rem;
}
.icon6 {
  top: calc(50% - 53px);
  left: calc(50% - 65px);
  width: 8.188rem;
  height: 6.688rem;
}
.icon10,
.icon11,
.icon7,
.icon8,
.icon9 {
  position: absolute;
  top: calc(50% - 29px);
  left: calc(50% - 88px);
  width: 11.063rem;
  height: 3.625rem;
  object-fit: cover;
}
.icon10,
.icon11,
.icon8,
.icon9 {
  top: calc(50% - 50px);
  left: calc(50% - 89px);
  width: 11.188rem;
  height: 6.25rem;
}
.icon10,
.icon11,
.icon9 {
  top: calc(50% - 66px);
  left: calc(50% - 71px);
  width: 8.875rem;
  height: 8.25rem;
}
.icon10,
.icon11 {
  top: calc(50% - 54px);
  left: calc(50% - 69px);
  width: 8.625rem;
  height: 6.75rem;
}
.icon11 {
  top: calc(50% - 43px);
  left: calc(50% - 85px);
  width: 10.688rem;
  height: 5.438rem;
}
.frameDiv {
  position: relative;
  /* top: 17rem;
  left: 8.438rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xl);
  flex-wrap: wrap;
  margin: 50px auto 52px;
}
.chevronLeftIcon {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  width: 2rem;
  height: 2rem;
}
.chevronLeftParent {
  width: 2rem;
  position: relative;
  height: 2rem;
}
.div1,
.groupChild1 {
  position: absolute;
  top: 0;
}
.groupChild1 {
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-mediumvioletred);
  width: 2rem;
  height: 2rem;
}
.div1 {
  left: 0.813rem;
  line-height: 2rem;
  font-weight: 600;
}
.rectangleParent {
  width: 2rem;
  position: relative;
  height: 2rem;
  color: var(--base-white);
}
.groupChild2 {
  position: absolute;
  top: 0.063rem;
  left: 0;
  border-radius: var(--br-9xs);
  width: 2rem;
  height: 2rem;
}
.div2 {
  position: absolute;
  top: 0;
  left: 0.688rem;
  line-height: 2rem;
  font-weight: 600;
}
.rectangleGroup {
  width: 2rem;
  position: relative;
  height: 2.063rem;
}
.pagination {
  position: absolute;
  top: 71.25rem;
  left: calc(50% - 160px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--text-single-100-bold-size);
  color: var(--color-dimgray-100);
}
.imagePlaceholderParent {
  width: 100%;
  position: relative;
  background-color: var(--base-white);
  height: 103.438rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--paragraph-semibold-body-16);
}

.logoContainer{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap:3.25rem; */
  flex-direction: column;
  /* padding-left: 8.44rem;
  padding-right: 8.5rem; */
  flex-wrap: wrap;
  margin-top:4.52rem;
  width:73.125rem;
}

 /* .page-link>.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #CB0064;
  border: none;
} */





.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #CB0064 !important;
}

/* Increase specificity */
.MuiPaginationItem-root.Mui-selected {
  background-color: #CB0064 !important;
}


.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
  width: 2rem !important;
  height: 2rem !important;
  flex-shrink: 0;
}



@media (min-width: 1441px){
/* .logoContainer{
  padding-left: 14.44rem;
} */
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .logoContainer{
    padding-left: 1.3rem !important;
  }
}

.downloadIcon{
  width: 2rem;
  height: 2rem;
  position: relative;
  /* top: 12.63rem;
  right: 6.3rem; */
  display: none;

}

.editIcon{
  width: 2rem;
  height: 2rem;
  position: relative;
  /* top: 12.63rem;
  right: 8.62rem; */
  display: none;
}

.visible {
  display: block; 
}
.activeColor{
  background: #F7EEEE;
}

@media (max-width: 768px) {
  .logoContainer{
    width:100% !important; 
    margin-bottom: 2.88rem;
    margin-bottom: 3.33rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .frameDiv {
    display: block;
    width:100%;
  }

  .chooseALogo{
    font-size: 1.8125rem;
  }

  .component20Parent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .component20 {
    width: 100%;
    max-width: 20rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .logoContainer{
    width:100% !important; 
    margin-bottom: 2.88rem;
    margin-bottom: 3.33rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .frameDiv {
    display: block;
    width:100%;
  }

  .chooseALogo{
    font-size: 1.8125rem;
  }

  .component20Parent {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
  }
  .component20 {
    flex: 0 0 48%; /* Take up 50% of the row, effectively showing 2 items per row */
  }
}


@media (min-width: 992px) and (max-width: 1230px) {
 .logoContainer{
    width:100% !important; 
    margin-bottom: 2.88rem;
    margin-bottom: 3.33rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .component20Parent {
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
  }
  .component20 {
    flex: 0 0 48%; /* Take up 50% of the row, effectively showing 2 items per row */
  }

}