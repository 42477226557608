@import '../stylesheet.css';

.paragraphSec1 {
    color: #141414;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* width: 27.75rem; */
    margin-top: 0.75rem;
    margin-bottom: 2rem;
}

.bulletTextSect1 {
    color: #141414;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    margin-bottom: 0.62rem !important;
}

.bulletHeadingSect1 {
    color: #141414;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.pricingStyle {
    color: #D93870 !important;
    font-family: Poppins;
    font-size: 1.0625rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;

}

.getStartedBtn {
    width: 8.5rem;
    height: 2.3125rem;
    background-image: linear-gradient(90deg, #cb0064, #9300b8, #cb0064, #9300b8);
    border: none;
    border-radius: 6px;
    color: #FFF !important;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins !important;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    background-size: 300%;
    background-position: left;
    transition: 1s background-position ease-in-out;
}

.getStartedBtn:hover {

    background-position: right;


}

.topImage {
    width: 34.0625rem;
    height: 25.4375rem;
    background: cover no-repeat;

}

.customList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.customList li {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;
}

/* .customList li::before {
    content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3651 4.73431C13.6775 5.04674 13.6775 5.55326 13.3651 5.86569L6.9651 12.2657C6.65268 12.5781 6.14615 12.5781 5.83373 12.2657L2.63373 9.06568C2.32131 8.75328 2.32131 8.24674 2.63373 7.93431C2.94615 7.6219 3.45268 7.6219 3.7651 7.93431L6.39941 10.5686L12.2337 4.73431C12.5461 4.4219 13.0527 4.4219 13.3651 4.73431Z" fill="#CB0064"/></svg>');
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    color: rgba(203, 0, 100, 1);
} */

.bulletIcon {
    width: 1rem;
    height: 1rem;
    margin-top: 0.15rem;
}


/* section 3 */

.stepsButton {
    display: flex;
    width: 11.625rem;
    height: 3.125rem;
    padding: 1.4375rem 1.8125rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.3125rem;
    background: #F6F6F6;
    color: #141414;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    flex: 0 0 auto;
}

.stepsWhiteButton{
    background-color: #FFF;
}

.nextIcon {
    width: 0.75rem;
    height: 0.75rem;
}

.stepsButtonsContainer {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    gap: 0.75rem;
    width: calc(111px* 5);
    max-width: 100%;
    overflow-x: auto; /* Horizontal scroll */
    overflow-y: hidden; /* Hide vertical scroll */
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    
  }

.stepsButtonsContainer::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
}

.stepsButton {
    flex-shrink: 1;
    /* Allow button to shrink */
    flex-grow: 0;
    /* Prevent button from growing */
    min-width: 9.3rem;

}

/* .stepsButton:first-child {
    margin-left: 0.75rem;
}

.stepsButton:last-child {
    margin-right: 0.75rem;
} */

.stepsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    margin-top: 2.62rem;
    -webkit-overflow-scrolling: touch;
}

.InnerContainer {
    width: 48.75rem;
    height: 13.1875rem;
    flex-shrink: 0;
    flex-shrink: 0;
    background: rgba(246, 246, 246, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
}

.stepHeading {
    color: #141414;
    font-family: 'gilroybold';
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    padding-top: 1.63rem;
}

.stepPara {
    color: #141414;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 171.429% */
    text-align: left;
    margin: 0 !important;
    width: 100%;
}

.stepButton {
    color: #CB0064;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    /* 128.571% */
    text-decoration-line: underline;
    margin-top: 1.63rem;
    border: none;
    background-color: transparent;
    padding: 0 !important;
}

.stepTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.62rem;
    padding-left: 3.25rem;
}

.activeButton {
    background: #CB0064;
    /* Change this to your desired active color */
    color: white;
}


/* Pricing Section */

.cardWrapper {
    width: 24.625rem;
    height: 29.9375rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    border: 1px solid #EEE;
    background: #FFF;

}

.imageContainer {
    width: 24.5rem;
    height: 17.8125rem;
    flex-shrink: 0;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    overflow: hidden;
    /* background: url(<path-to-image>) lightgray -0.163px -19.755px / 100.086% 124.904% no-repeat; */
}

.pricingCardLabel {
    color: #141414;
    font-family: 'gilroybold' !important;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    margin: 0 !important;
    padding-bottom: 2.06rem;
    padding-top: 2.19rem;
    display: flex;
    justify-content: flex-start;
    gap: 1.31rem;
    align-items: center;

}

.pricingCardTag {
    width: 6.9375rem;
    height: 1.875rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: linear-gradient(90deg, #9300B8 0%, #CB0064 100%);
    color: #FFF;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricingCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.25rem;
    padding-left: 1.13rem;

}

/* ScrollSection */



.scrollSection {
    width: 73.125rem !important;
    margin-top: 6.88rem !important;
    margin-bottom: 6.88rem !important;
    height: 38.31rem;
    overflow-x: hidden;
    /* Enable horizontal scrolling */
    overflow-y: scroll;
    /* Disable vertical scrolling */
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid transparent;
    border-image-source: linear-gradient(350deg, #CB0064 -150.45%, #9300B8 119.09%);
    border-image-slice: 1;
    display: flex;
    padding: 0;
    align-items: flex-start;
    scrollbar-width: none;
    /* Firefox */
}

.scrollSection::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.scrollContent {
    display: flex;
    padding: 0;
    align-items: flex-start;
    gap: 1rem;
    /* Width of both content boxes combined */
}

.contentBox {
    width: 35.5rem;
    /* height: 56.0625rem; */
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: #FFF;
    padding: 1.88rem;
    margin: 0;
    text-align: left;
}

.contentBox:last-child {
    border-right: none;
    /* Remove the border from the last box */
}

.contentHeading {
    color: #141414;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'gilroybold';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.contentSubHeading {
    color: #141414;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'gilroybold' !important;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
}

.contentPara {
    color: #414141;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 32.3125rem;

}



/* CTA Section */

.CTACardWrapper {
    width: 5.5625rem;
    height: 4.1875rem;
    flex-shrink: 0;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CTAsectionHeading {
    color: #141414;
    text-align: center;
    font-family: 'gilroyExtraBold';
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* .CTAImageWrapper {
    width: 4.0625rem;
    height: 3.0625rem;
    flex-shrink: 0;
} */



/* ExploreSection */

.exploreSectionCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.0625rem;
    border-radius: 0.625rem !important;
    /* padding: 0 16px; */
    /* border: 1px solid #f6f0f0; */
}

.exploreSectionCardImg {
    width: 16.5625rem;
    height: 14.625rem;
    border-radius: 0.625rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exploreSectionCaption {
    color: #000;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 120% */
}

/* .cardContainer {
    width: 17.25rem;
    height: 19.5rem;
    border: 1px solid #f6f0f0;
    border-radius:0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 16px;
    margin: 10px;
    z-index: 1; 
    background: white;
  } */






/* browsse section */


.iconButtonText {
    color: #141414;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.browseActiveText {
    color: #CB0064;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}









/* .slide {
    transition: transform 0.5s ease-in-out, opacity 1.5s ease-in-out;
}


.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: flex;
    justify-content: flex-end;
}

.carousel-inner {
    display: flex;
} */
.carousel-inner>.carousel-item {
    position: relative;
    display: none;
    -webkit-transition: 6s ease-in-out left;
    -moz-transition: 6s ease-in-out left;
    -o-transition: 6s ease-in-out left;
    transition: 6s ease-in-out left;
}

.carousel-item {
    display: none;
    position: relative;
    transition: 6s ease-in-out left;
}

/* .customTransition{
    transition: transform 1s ease-in-out;
} */
.carousel-item {
    transition: transform 2s ease, opacity 0.5s ease-out !important;
}



/* Order section */
.OrderSectionWrapper {
    width: 73.0625rem;
    height: 15.9375rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid #F8F8F8;
    background: #F8F8F8;
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid #F8F8F8;
    background: linear-gradient(90deg, #9300B8 0%, #CB0064 100%), linear-gradient(0deg, #CB0064 0%, #CB0064 100%), #F8F8F8;
}

.orderText {
    color: #fff;
    text-align: center;
    font-family: 'gilroybold';
    font-size: 1.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.orderTextSection {
    color: #141414;
    text-align: center;
    font-family: 'Gilroy-Regular', sans-serif !important;
    font-size: 1.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
}

.stepsIcon {
    /* margin-top:3rem; 
    margin-left:8.5rem; */
    width: 10.5625rem;
    height: 10.3125rem;
}

.stepIconWrapper {
    /* width: 81%; */
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

@media (max-width: 768px) {

    .heroGap {
        gap: 3.75rem !important;
        flex-direction: column !important;
    }

    .InnerContainer {
        width: 100%;
    }

    .OrderSectionWrapper {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }



    .MinWidth {
       min-width: 100%;
    }

    .stepsIcon {

        width: 7.5625rem;
        height: 7.3125rem;

    }

    .scrollSection,
    .contentBox,
    .contentPara {
        width: 100%
    }

    .scrollContent {
        flex-direction: column;
    }
    .pricingSection{
        width:100%;
    }

}

@media (max-width: 576px) {
.pricingCardLabel{
    font-size: 0.9375rem !important;
}
.pricingCardBody .stepHeading{
    font-size: 1rem !important;
}
    .stepsIcon {

        width: 5rem;
        height: 5rem;

    }

    .heroGap {
        gap: 3.75rem !important;
        flex-direction: column !important;
    }

    .cardWrapper{
        width: 21.6875rem !important;
        height: auto;
    }
    .imageContainer{

        width:100% !important;
    }
    .pricingCardBody .stepPara{
        width: 100% !important;
    }
    .stepsButtonsContainer{
        width: calc(111px* 5);
    }
    .stepsButtonsContainer {
        padding-left: 3rem;
    }
    .container.stepsButtonsContainer {
        padding-left: 0 !important;
    }
    .TestimonialSection .stepsButtonsContainer{
        gap:4.75rem;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .OrderSectionWrapper {
        width: 100%
    }

    .heroGap {
        gap: 3rem !important;
        width: 100%;
        overflow: hidden;

    }

    .topImage {
        width: 80%;
        height: auto;
    }

    .scrollSection,
    .contentPara{
        
        width: 100%
    }
    .scrollSection .contentBox {
       width:28rem;
      }
      .customizeMargin{
        margin-left:13% !important;
    }

    .stepsButtonsContainer{
        width: 100%;
        justify-content: center;
    }
    .OverflowHidden{
        overflow: hidden;
    }
 
}


@media (min-width: 768px) and (max-width: 992px) {
    .OrderSectionWrapper {
        width: 100%
    }

    .heroGap {
        gap: 3.75rem !important;
        flex-direction: column !important;
    }

    .scrollSection,
    .contentBox,
    .contentPara {
        width: 100%
    }

    .scrollContent {
        flex-direction: column;
    }

    .stepsButtonsContainer{
        width: calc(148px* 5);
    }


    }

    @media(min-width:1201px){
        .stepsButtonsContainer{
            width: 100%;
            justify-content: center;
        }
        
    }