.text-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #141414;
  text-align: left;
}

.dot {
  color: #d93870;
  font-family: "Gilroy-ExtraBold";
  font-size: 55px;
  font-weight: 400;
  line-height: 44px;
}

.heading-two-col {
  font-family: "Gilroy-Bold";
  font-size: 35px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: left;
  color: #000000;

}

.brandWrapper {
  width: 73.125rem;
  padding-top: 7rem;
  /* padding-bottom: 4.38rem; */
  padding-bottom: 2.38rem;
  /* height: fit-content; */

}
.twoColImageCont{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}


/* Responsive Styles */


@media (max-width: 1200px) {
  .text-container h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .heading-two-col {
    font-size: 30px;
    line-height: 40px;
  }

  .dot {
    font-size: 50px;
    line-height: 40px;
  }
}

@media (max-width: 992px) {
  .text-container h3 {
    font-size: 28px;
    line-height: 36px;
  }

  .heading-two-col {
    font-size: 28px;
    line-height: 36px;
  }

  .dot {
    font-size: 45px;
    line-height: 36px;
  }
}

@media (max-width: 768px) {

  .brandWrapper {
    width: 100% !important;
    padding-top: 4.38rem !important;
    padding-bottom: 3rem !important;

  }

  .BrandIllustration {
    left: 4px !important;
    top: -52px !important;
    width: 90% !important;
  }
.twoColImageCont{
  width: 100%;
  height:'auto';
}
  .BrandImage{
    width: 80% !important;
  }
  .removePadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .twoSectionColGap {
    gap: 2.5rem !important;
  }

  .alternate {
    flex-direction: column-reverse;
    
    

  }
.alignCenterSmall{
  align-items: center !important;
}


  .text-container h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .text-container p {
    font-size: 13px;
    line-height: 22px;
  }

  .heading-two-col {
    font-size: 24px;
    line-height: 32px;
  }

  .dot {
    font-size: 40px;
    line-height: 32px;
  }

  .two-col-para {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .text-container h3 {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .text-container p {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }

  .heading-two-col {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .dot {
    font-size: 35px;
    line-height: 28px;
  }

  .reverse-col {
    flex-direction: column-reverse
  }
}

@media (max-width: 576px) {
  .BrandImage{
    width: 100% !important;
  }
  .brandWrapper{

    padding-top: 4.38rem !important;
    padding-bottom: 0rem !important;
  }
  .BrandIllustration {
    left: 4px !important;
    /* top: -132px !important; */
    width: 90%!important;
  }
  .BrandImage{
    width: 90% !important;
  }
}


@media (min-width: 992px) and (max-width: 1230px) {

  .brandWrapper {
    width: 100% !important;
    padding-top: 4.38rem !important;
    padding-bottom: 3rem !important;

  }

  .BrandIllustration {
    left: 25px !important;
    top: -2px !important;
    width: 90% !important;
  }
.twoColImageCont{
  width: 100%;
  height:'auto';
  align-items: center;
}
  .BrandImage{
    width: 100% !important;
  }
  .removePadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  /* .twoSectionColGap {
    gap: 2.5rem !important;
  } */
}



@media (min-width: 768px) and (max-width: 992px) {

  .brandWrapper {
    width: 100% !important;
    padding-top: 4.38rem !important;
    padding-bottom: 3rem !important;

  }

  .BrandIllustration {
    left: 2px !important;
    top: -2px !important;
    width: 100% !important;
  }
.twoColImageCont{
  width: 100%;
  height:'auto';
  align-items: center;
}
  .BrandImage{
    width: 100% !important;
  }
  .removePadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .twoSectionColGap {
    gap: 3.5rem !important;
  }
}
