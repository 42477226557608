.underline-input {
    border: none;
    border-bottom: 2px solid #ccc; /* Customize the color and thickness */
    border-radius: 0;
    outline: none;
    box-shadow: none;
    background-color: transparent; /* Make sure the background is transparent */
    position:relative;
    margin-top: 3rem;
    width: 27.65625rem;
}

.underline-input:focus {
    border-bottom: 1px solid #CB0064; /* Change the color on focus */
    outline: none;
    box-shadow: none;
    position: relative;
}