@import '../stylesheet.css';

.button-container {
  position: relative;
  width: 4.875rem;
  height: 4.875rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(147, 0, 184, 0.05) 0%, rgba(203, 0, 100, 0.05) 100%);
  border-radius: 50%;
  z-index: 1;
  transition: none !important;
  transform: none !important;
}


.buttonActive {
  background: linear-gradient(90deg, #9300B8 0%, #CB0064 100%);
  border: 1px solid #9300B8;
  z-index: 2;
}

/*   
  .button-container svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */

.icon-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
  transition: none !important;
  transform: none !important;
}

.icon {
  transition: none !important;
  transform: none !important;
}

/* .icon:hover {
    fill: #fff; 
  } */

/* .icon-container .icon {
    transition: fill 0.3s ease;
    z-index: 10;
  
  }
  .buttonActive .icon-container .icon {
  fill: white; 
}
  
  .button-container:hover .icon {
    fill: url(#paint0_linear_287_1413);
  }
  
  svg.active circle {
    fill: url(#paint0_linear_287_1418);
    stroke-width: 1px;
    stroke: #9300B8;
    fill-opacity: 1;
  } */


/* .icon1{
  
      fill: #ffffff;
    
  } */

/* .button-container.buttonActive .icon-shape {
    fill: white;
  } */

.svgActive {
  display: none;
}

.svgActive1 {
  opacity: 1;
}

