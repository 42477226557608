.loremIpsumDolor {
    position: relative;
    /* top: 0;
    left: 0; */
    line-height: 1.625rem;
    display: inline-block;
    width: 24.4375rem;
    color: #B3B3B3;

font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 185.714% */
  }

  .logoLogoworks1Icon {
    position: relative;
 
    width:4.39606rem;
    height:1.82625rem;
    overflow: hidden;
  }
  .socialLinks {
    position: relative;
 
    height: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:2rem;
   
    margin-top: 2.78rem;
  }

  .noLinkStyle {
    text-decoration: none;
    color: inherit;
    display: inline-block; /* Ensures the element does not behave like a text element */
    margin: 0; /* Reset any default margins */
    padding: 0; /* Reset any default padding */
    border: none; /* Reset any default border */
    background: none; /* Reset any default background */
}


.facebookIcon {

  width: 100%;
  height: 100%;
  color: #FFF;

}

.firstColContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap:2rem;
    flex-direction:column;
    padding-bottom: 1rem;
}
.item-3 {
    margin-top: 1.11rem; 
  }

  .container {
    position: relative;
    /* top: -24.494rem;
    left: 17.563rem; */
    background-color: var(--base-white);
    width: 100%;
    bottom: 0;
    background: #141414;
    height: 26.375rem;
    padding-top: 7.5rem;
    padding-left: 11.58;
    padding-left: 12.62;


  }

  .links {
  position: relative;

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap:1.5rem;

}

.singleService {
  position: relative;
 
  color: #B3B3B3;

font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem; /* 142.857% */
text-wrap: nowrap;
}

.footerRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:4.11rem;
  padding-bottom: 1.5rem;

}
.company {
  position: relative;

  color: #FFF;
font-family: Poppins;
font-size: 1.0625rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; /* 141.176% */
}

.enterYourEmail1 {
  position: relative;
  /* top: 0.688rem;
  left: 1.25rem; */
  line-height: 1.625rem;
  width: 16.875rem;
/* height: 3rem; */
flex-shrink: 0;
display: flex;
align-items: center;
}

.emailText{
  color: #FFF;

  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
width: 16.875rem;

}
.enterYourEmail1{
  width: 16.875rem;
height: 3rem;
flex-shrink: 0;
color: #FFFFFF;

}
.emailCopy{
  color: #FFF;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 171.429% */
}


.logoMakerParent {

  width: 10rem;
  height: 2.55rem;
  text-align: left;
  font-size: var(--font-size-lg-1);
  font-family: var(--font-syncopate);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap:0.3rem;

}

.logoText {
  margin: 0;

}

.logoMaker {


  color: #FFF;

font-feature-settings: 'clig' off, 'liga' off;
font-family: Syncopate;
font-size: 0.89581rem;
font-style: normal;
font-weight: 700;
line-height: 0.89581rem;
}


.groupItem {
  margin:0;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
.customInput{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;
  padding-left: 1rem !important;
}
.customInput::placeholder{
 

  margin-top: -100px;
  width: 100%;
  color: #B3B3B3;
height: 3rem;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 300;
line-height: 1.625rem;
vertical-align: middle;

 
}
.midColGap{
  gap:6.06rem;
}

.customInput:focus{
   outline: none !important;
  border:1px solid  #cb0064 !important;
  box-shadow: 0 0 10px #719ECE;
}

@media (max-width:768px){
  .footerRow{
    flex-direction: column;
    gap:3.12rem;
    align-items: flex-start;
 
  }
  .container{
    
    height: auto;
    padding-top: 1.87rem;
    padding-left: 1rem !important;
    display: flex;
    justify-content: center;
  
  }
  .thirdColumn{
    margin-left: 0 !important;
  }
  .midColGap{
    gap:2.7rem !important;
  }

  .loremIpsumDolor{
    width: 100%;
  }
}
@media (max-width:576px){
  .container{
    justify-content: flex-start;
    padding-left: 1rem !important;
  }


}

@media (min-width: 992px) and (max-width: 1230px) {
  .footerRow{
    gap:2%;
  }
  .midColGap{
    gap:4.06rem !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .footerRow{
    flex-direction: column;
    gap:3.12rem;
    align-items: flex-start;
 
  }
  .container{
    
    height: auto;
    padding-top: 1.87rem;
    padding-left: 1rem !important;
    display: flex;
    justify-content: center;
  
  }
  .thirdColumn{
    margin-left: 0 !important;
  }
  

}