.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.75rem;
  }
  
  .pageButton {
    border: none;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0 0.25rem;
    font-size: 1rem;
  }
  
  .pageButton:hover {
    background-color: #CB0064; /* Hover background color */
    color: white; /* Hover text color */
    border-radius: 5px;
    width: 2rem;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .activePage {
    background-color: #CB0064; /* Active page background color */
    color: white; /* Active page text color */
    border-radius: 5px;
    width: 2rem;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrowButton {
    border: none;
    background-color: transparent;
    color: #CB0064;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 0.25rem;
  }
  
  /* .arrowButton:hover {
    background-color: #E0E0E0; 
  } */
  
  .arrowButton:disabled {
    color: #CCC; /* Disabled arrow button color */
    cursor: not-allowed;
  }
  