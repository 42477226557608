.navbarParent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6.94rem;

  padding-top: 2.46rem;
  font-family: var(--font-gilroy-bold);
  width: 73.125rem !important;
  padding-bottom: 0 !important;

}

.navbarFirstComp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 9.13rem;
  margin-left: 0;
  /* overflow: hidden; */
}

.navbarSecondComp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  gap: 1.75rem;
  padding: 0;
  /* padding-top: 0.9rem; */
  margin: 0;
  color: #434343;
  width: 29.4rem;
  flex-wrap: nowrap;

}

.navbarSecondComp li>ul {

  flex-direction: column;

  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;

  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 13px;
  padding-right: 59px;
  border-radius: 8px;
  margin-top: 17px;
  padding-left: 25px;
  display: flex;
  height: 7.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  display: none;

}

.navbarThirdComp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  gap: 0.8rem;
  padding-top: 2.1px;
  margin: 0 !important;
  font-family: Poppins !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;

}


.menuItems {
  color: #434343;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* 1.00625rem */
  text-wrap: nowrap;
  width: fit-content;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap; */


}

.menuNumber {
  color: #535353;

  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins !important;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 500 !important;

}

.groupItem {

  /* width: 2.55rem;
  height: 2.55rem; */
  width:100%;
  height:100%;
}

.logoMakerParent {
  /* top: 0.063rem;
    left: 0; */
  width: 10rem;
  height: 2.55rem;
  text-align: left;
  font-size: var(--font-size-lg-1);
  font-family: var(--font-syncopate) !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.3rem;
  /* background-color: #cb0064; */
}

.logoText {
  margin: 0;
  /* 100% */
}

.logoMaker {
  /* position: absolute; */
  /* width: 5.5625rem;
    height: 2.3125rem;
    line-height: 1.131rem; */

  color: #535353;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Syncopate;
  font-size: 1.13138rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.13138rem;
}

.image40Icon {
  position: relative;

  width: 1.25rem;
  height: 1.25rem;
  object-fit: cover;
}

.masterPrimaryButton2 {
  position: relative;

  border-radius: var(--br-5xs);
  border: 1px solid var(--color-mediumvioletred);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  gap: var(--gap-8xs);
  color: var(--color-black);
  font-family: var(--font-gilroy-semibold);
  margin-top: -0.69rem;
}

.buttonText3 {
  position: relative;
  line-height: 115%;
  z-index: 0;
}

.masterPrimaryButtonChild {
  width: 1.313rem;
  position: absolute;
  margin: 0 !important;
  top: 2.625rem;
  left: calc(50% - 11px);
  border-radius: 50%;
  background: linear-gradient(87.8deg, #9300b8, #cb0064 58.4%);
  height: 1.25rem;
  z-index: 1;
}


.getStartedBtn {
  width: 112px;
  height: 37px;
  background-image: linear-gradient(90deg, #cb0064, #9300b8, #cb0064, #9300b8);
  border: none;
  border-radius: 6px;
  color: #FFF !important;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins !important;
  font-size: 0.875rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  background-size: 300%;
  background-position: left;
  transition: 1s background-position ease-in-out;
}

.getStartedBtn:hover {

  background-position: right;


}

.line9 {
  width: 0.0625rem;
  height: 1.875rem;
  background: #E6DDE8;
}


.navbarFirstComp>a {
  text-decoration: none;
}


/* @media (min-width: 1441px) {
  .navbarParent {
    padding-left: 0;
    padding-right: 0;
    margin: auto;
  }
} */


@media (min-width: 992px) and (max-width: 1230px) {
  /* .navbarFirstComp{
    gap:5%;
  } */

  .navbarParent {
    flex: 0 0 auto;
    width: 91.66666667% !important;
  }
}


@media (min-width: 992px) and (max-width: 1230px) {


  .navbarParent {
    flex: 0 0 auto;
    width: 100% !important;

  }
  .navbarFirstComp{
    gap:5%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .navbarFirstComp{
    gap:5%;
  }

  .navbarParent {
    flex: 0 0 auto;
    width: 100% !important;
   
  }
}
