@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
@import '../stylesheet.css';

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
}

.heading {
  font-family: "Gilroy-bold";
  font-size: 2.2rem;
  line-height: 3.125rem;
  /* 50px */
  color: #141414;
  margin-bottom: 1rem;
  font-weight: 400;
  text-align: left;
}

.description {
  font-family: "Poppins";
  font-size: 1.0625rem;
  /* 17px */
  line-height: 1.625rem;
  /* 26px */
  color: #141414;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.custom-select,
.custom-input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  font-family: "Poppins";
  font-size: 1rem;
  /* 14px */
  font-weight: 400;
  line-height: 18px;
  color: #141414;
  border-bottom: 1px solid #14141499;
  color: #878787;
}

.custom-select:focus,
.custom-input:focus {
  border-bottom: 2px solid #d93870;
}

.btn-primary {
  background: linear-gradient(90deg, #9300b8 0%, #cb0064 100%);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "Poppins";
  font-size: 1rem;
  /* 16px */
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
}

.btn-primary:hover {
  background: linear-gradient(90deg, #cb0064 0%, #9300b8 100%);
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}

.info-section {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.info-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.arrow-container {
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 6rem;
  animation: zoom 2s infinite;
}

.why-card {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
  width: 21.375rem;
}


@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .custom-select,
  .custom-input {
    font-size: 0.875rem;
  }

  .btn-primary {
    font-size: 0.875rem;
  }

  .why-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .why-card {
    text-align: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
  }

  .MakeLogo-right-section {
    display: none !important;
  }
}

/* LogoCards section  */

.image-container {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 90%; */
  margin: 0 auto;
  /* height: 265px; */
  width: 21.4375rem;
  height: 16.5625rem;
  margin-top: 15px;

  background: #f8f8f8;
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding-top: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.card-img-top {
  width: auto;
  height: auto;
}

.card-text-cap {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.76px;
  text-align: left;
}

.test-card-text {
  overflow: hidden;
  color: #141414;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  width: 18.4375rem;
  height: 9rem;

  /* Multiline ellipsis */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; /* Adjust the number to set how many lines you want before ellipsis */
  line-clamp: 4; /* For modern browsers */
}

/* .slider-controls {
  margin-top: 20px;
} */

.slider-button {
  /* width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #d3d3d3; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  /* font-size: 20px; */
}

.slider-button.active {
  background: rgba(217, 56, 112, 1) !important;
  opacity: 1 !important;
}

.slider-button:disabled {
  opacity: 0.5;
}

.testimonialSection .card {
  border-radius: 10px;
  width: fit-content;
}

.testimonialSection .card-footer {
  background-color: transparent;
  border-top: none;
}

.test-card-body {
  background-color: #f8f8f8;
  max-width: 22.1875rem;
  width: 22.1875rem;
  height: 14.875rem;
  gap: 0px;
  border-radius: 10px;
  padding: 1.63rem 1.88rem 1.75rem 1.88rem !important;

}

.card-footer {
  padding-top: 2rem !important;
  text-align: left;

}

.card {
  border: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #d93870;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px;
  height: 50px;
}

/* Button style */
.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.prev-button {
  background: rgba(223, 207, 226, 0.7);
  margin: 3px;
  border: none;
}

/* Next button */
.next-button {
  background: #d93870;
  margin: 3px;
  border: none;
}

/* Active button */
.activebtn {
  background: #cb0064;
}

@media (max-width: 992px) {
  .d-lg-none {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

  .large-screen {
    display: none;
  }
}

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
    /* gap:1.62rem !important; */
  }

  .d-none {
    display: none !important;
  }
}

/* why choose us section  */
.why-card {
  text-align: left;
}

.dot {
  color: #d93870;
  font-family: "Gilroy-Bold";
  font-size: 35px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: left;
}

.questionMark {
  color: #D93870;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'gilroyExtraBold';
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-text-wc {
  font-size: 14px;
  font-family: "Poppins";
}

/* MakeLogo.css */

/* Card Slider */
.cardSlider {
  position: relative;
  overflow: hidden;
}

.cardContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease-in-out;
}



/* Slider Controls */
.slider-controls {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.slider-button {
  width: 0.875rem;
  height: 0.875rem;
  background: rgba(223, 207, 226, 0.7);
  border-radius: 50%;
  border: none;
  margin: 0 5px;
  cursor: pointer;
}

.slider-button.active {
  background: #d93870;
}

.card-container-large-screen {
  display: flex;
  width: 100%;

}

@media (min-width: 992px) {
  .slider-controls {
    display: none;
  }

  .card-container-small-screen {
    display: none !important;
  }

  /* .card-container-large-screen {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
  } */

  .card-container-large-screen .col {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 991px) {
  .card-container-large-screen {
    display: none !important;
  }

  .card-container-small-screen {
    display: flex !important;
    position: relative;
  }
}

.slider-button.active {
  background: #d93870;
}


.custom-card {
  width: 23.6875rem;
  height: 22.25rem !important;
  flex-shrink: 0;
}


/* Sample logos section */

.logosWrapper {
  width: 73.125rem;
  padding-top: 4.38rem;
  padding-bottom: 4.38rem;
 

}

.logosHomeWrapper {
  width: 73.125rem;
  padding-top: 4.38rem;
  padding-bottom: 4.38rem;
 

}

.sectionHeading {
  color: #141414;
  font-family: 'gilroyExtraBold';
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.63rem;
}

.dotStyle {
  color: #D93870;
  font-family: 'gilroyExtraBold';
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.customCaption {
  color: #141414 !important;
  font-family: 'gilroybold' !important;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btnIcon1 {
  width: 0.875rem;
  height: 0.875rem;
  display: none;
}

.btnIcon2 {
  width: 0.875rem;
  height: 0.875rem;
}

.btnIcon1 {
  display: none;
}

.btnIcon2 {
  display: block;
}


.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


button:disabled .btnIcon1 {
  display: block;
}

button:disabled .btnIcon2 {
  display: none;
}

.cardImg {
  width: 21.4375rem;
  height: 16.5625rem;
}

.logosrow {
  display: flex;
  transition: transform 500mss ease-in-out;
}

.logosrow.next {
  transform: translateX(100%);
}

.logosrow.prev {
  transform: translateX(-100%);
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-inner.next {
  transform: translateX(-100%);
}

.carousel-inner.prev {
  transform: translateX(100%);
}

.carousel-item {
  min-width: 100%;
  display: none;
}

.carousel-item.active {
  display: flex;
}


/* Testimonial Section */



.testimonialSection {
  width: 73.125rem !important;
  padding-top: 6.88rem !important;
  padding-bottom: 6.88rem !important;
  
  
}

.test-name {
  color: #141414;
  font-family: 'gilroybold';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
}

.test-detail {
  color: #141414;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06rem;
}


/* whychose */
.sectionDescription {
  color: #141414;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.whyChooseWrapper {
  width: 73.125rem !important;
  padding-top: 6.87rem ;
  padding-bottom: 6.87rem ;
  
}
.customStepsWrapper{
  width: 73.125rem !important;
}

.cardWrapper {
  margin-top: 2.5rem !important;
  display: flex;
  justify-content: center;
  column-gap: 4.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-wrap: nowrap;

}

.cardWrapperSecond {
  margin-top: 3.5rem !important;
  display: flex;
  justify-content: center;
  column-gap: 4.5rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-wrap: nowrap;

}

.why-card-img {
  margin-bottom: 0.75rem;
}

.cardTitle {
  color: #141414;
  font-family: 'gilroybold';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.cardText {
  color: #141414;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 171.429% */
  width: 21.375rem;
  text-align: left;
}

.gap-after {
  margin-bottom: 3.5rem;
}

.colCont {
  width: fit-content !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ctoHeading{
  color: #141414;

  text-align: center;
  font-family: 'gilroybold';
  font-size: 1.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ctoHeading > span{
  color: #141414;
  text-align: center;
  font-family: 'Gilroy-Regular', sans-serif;
  font-size: 1.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.carousal-item .active{
  transform:translateX(50%) !important;
} 

.browseSectionHeading {
  color: #141414;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'gilroyblack';
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
  width: 41.25rem;
  text-align: center;
}

.navbarOuterContainer{
  display: flex;
  justify-content: center;
  align-items: center;

  }



@media (max-width:768px){
  .logosHomeWrapper{
width:100%;
  }
  .sectionHeading, .browseSectionHeading{
    color: #141414;
    text-align: center;
    font-family: 'gilroyblack';
    font-size: 1.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100% !important;
    
   
  }
  .testimonialSection {
    width: 100% !important;
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .removePadding{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
.whyChooseWrapper {
  width: 100% !important;
  padding-top: 4.37rem ;
  padding-bottom: 4.37rem ;
  
}

.cardWrapper {

  flex-wrap: wrap;
  row-gap: 2.5rem !important;
}

.cardWrapperSecond {
  flex-wrap: wrap;
  row-gap: 2.5rem !important;
  margin-top: 2.5rem !important;
}

.why-card{
  align-items: center;
}
.cardText{
  text-align: center;
  width: fit-content !important;
  overflow: hidden;
  
}
.servicesStepsWrapper{
  padding-left: 4px !important;
}

.inline-block-visible{
  display: none !important;
}

.customStepsWrapper {
  width: 100% !important;
  padding-top: 7.5rem !important;
  padding-bottom: 7.62rem !important;
}

.logosWrapper{
  width: 100% !important;
  padding-top: 4.37rem !important;
  padding-bottom: 4.37rem !important;
}

.why-card{
  width: 100%;
}
.smallPadding{
  padding-left: 1rem;
  padding-right: 1rem;
}
}


@media (max-width:576px){
  .logosWrapper{
    width: 20rem !important;
  }
  /* .logosHomeWrapper{
width:20rem;
  } */
  .image-container{
    width: 90%;
    height:12.56rem;
  }
  .custom-card{
    
      width: calc(50% - -120px);
    height: 85% !important;
    /* width: 18.5rem;
    height: 17.375rem !important; */
  }
  .sectionHeading, .browseSectionHeading{
    width:95% !important;
  }
  .sectionDescription{
    width: 95%;;
  }

  /* .testimonialSection {
    width: 20rem !important;
  } */
   .testimonialSection .card{
    width: calc(50% - -150px);
   }

  .test-card-body{
    width:100%;

  }
  .test-card-text{
    width: 100%;
  }

  /* .whyChooseWrapper {
    width: 20rem !important;
  } */
  .cardText{
    width:88% !important;
  }

  .customStepsWrapper {
    width: 100% !important;

}
  
}

@media (min-width:576px){
.testimonialSection .cardContainer{
  gap: 1rem !important;
}
}


@media (min-width: 992px) and (max-width: 1230px) {


.navbarOuterContainer{
  padding-left: 2%;
  padding-right: 2%;
}

.cardText{
  width: 88%  !important;
}
/* .brandWapper .sectionHeading{
  width: 94% !important;
} */
.sectionHeading{
  width: 100% !important;
}
.logosHomeWrapper{
  width: 100%;
 }
 .testimonialSection{
  width:100% !important;
 }
 .cardWrapperSecond,.cardWrapper{
  column-gap: 0;
  padding-left: 2rem !important;
 }
 .overflowHidden{
  overflow: hidden;
 }

.image-container{
  width: 90%;
  height:12.56rem;
}
.custom-card{
  
    width: calc(50% - -175px); /* 2 cards per row with margin */
  height: 100% !important;
}

.testimonialSection .card{
  width: calc(50% - -150px);
 }

.test-card-body{
  width:100%;

}
.test-card-text{
  width: 100%;
}
.why-card{
  width: 100%;
}
 
}

@media (min-width: 768px) and (max-width: 992px) {


    .cardText{
      width: 88%  !important;
    }
    .sectionHeading{
      width: 100% !important;
    }

 .overflowHidden{
  overflow: hidden;
 }

 .cardWrapperSecond,.cardWrapper{
  flex-direction: column;
  align-items: center;
 }
 .logosHomeWrapper{
  width: 100%;
 }
 .testimonialSection{
  width:100% !important;
 }
}
