.frame-108155 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}
a {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
  color: #434343;
}

.user-icon {
  width: 26px;
  height: 26px;
  background: #e6dde8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.user-icon i {
  color: #000;
}

.line-9 {
  width: 1px;
  height: 30px;
  background: #e6dde8;
}

.phone-number {
  color: #141414;
  font-size: 14px;
}
.line {
  color: #e6dde8;
}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
  }

  .navbar-text {
    text-align: center;
    width: 100%;
  }

  .frame-108155 {
    flex-direction: column;
    align-items: center;
  }

  .get-started-btn {
    margin-left: 0;
    margin-top: 10px;
  }
}

.logo-txt {
  font-family: Syncopate;
  font-size: 16.77px;
  font-weight: 700;
  line-height: 16.77px;
  text-align: left;
  color: #535353 !important;
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  border-radius: 6px 0px 0px 6px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* gap:12.87rem; */
}

/* .sidebar .header img {
  height: 50px;
} */

.sidebar .header .close-btn {
  background: none;
  border: none;
  /* font-size: 1.5rem; */
  width: 1.0625rem;
height: 1.0625rem;
  cursor: pointer;
  padding: 0 !important;

  color: #000;
}

.sidebar .menu-items {
  margin-top:8px;
  width: 100%;
}

.sidebar .menu-item {
  font-size: 14px;
  color: #434343;
  padding: 16px;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1) !important;
  color: #434343;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 115%; /* 1.00625rem */
padding-left: 5px;
}

.sidebar .menu-item:last-child {
  border-bottom: none;
}

.sidebar .footer {
  margin-top: 9.13rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
}

.get-started-btn {
  background: linear-gradient(90deg, #cb0064 0%, #9300b8 100%);
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 10px;
  cursor: pointer;
  width: 13.9375rem;
height: 2.3125rem;
}

.phone-number {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #141414;
}

.navbar {
  width: 100%;
}

.navbar-toggler {
  outline: none;
}

.navbar-nav .nav-item .nav-link {
  color: #434343;
  transition: color 0.3s ease;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: left;
}

/* .navbar-nav .nav-item .nav-link:hover {
  color: #6c63ff;
} */

.navbar-collapse {
  width: 100%;
}

@media (min-width: 901px) {
  .sidebar {
    display: none;
  }

  .navbar-collapse {
    display: flex !important;
  }
}

@media (max-width: 900px) {
  .navbar-collapse {
    display: none !important;
  }

  .navbar-toggler {
    display: block;
  }
}

.custom-nav-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo-txt {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1rem;
  color: #000;
}

.get-started-btn {
 
  background: linear-gradient(90deg, #cb0064 0%, #9300b8 100%);
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
}

.custom-nav-section {
  flex-direction: row;
}

@media (max-width: 768px) {
.smallNavbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  /* gap: 14.3rem; */
  padding-top: 1.19rem;
}
}
@media (min-width: 768px) and (max-width: 991px) {
  .smallNavbar {
    gap: 70vw !important;
    display: flex !important;
    padding-top: 1.19rem;
    /* justify-content: space-between;
    max-width: 720px; */
  }
}
@media (min-width: 992px) {
  .smallNavbar{
    display: none !important;

  }
  }

