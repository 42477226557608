@import '../stylesheet.css';



.underlineInput {
  border: none !important;
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
  border-bottom: 1px solid rgba(20, 20, 20, 0.60) !important;
  
  outline: none;
  box-shadow: none;
  background-color: rgba(194, 194, 194, 0.1) !important;
  /* Make sure the background is transparent */
  width: 27.5rem;
  height: 3.875rem;
  max-height: 100%;
  color: black;
  display: flex;
  align-items: center;
}

.underlineInput:focus {
  border-bottom: 1px solid #CB0064 !important;
  outline: none !important;
  box-shadow: none !important;
}

.underlineInput::placeholder {
  color: #878787 !important;
  opacity: 1;
}

/* For older browsers */
.underlineInput:-ms-input-placeholder {
  color: #878787;
}

.underlineInput::-ms-input-placeholder {
  color: #878787;
}

.imageContainer,
.imagePlaceholder {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
}

.imageContainer {
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: var(--color-whitesmoke-300);
}

.imagePlaceholder {
  height: 38.04%;
  top: 10.05%;
  bottom: 51.91%;
  display: none;
}

.customPadding{
  padding-left: 5rem !important;
}


.freddieDeckow {
  position: relative;
  font-weight: 800;
}

.leadMarketingAdministrator {
  position: relative;
  font-size: var(--font-size-3xs);
  color: var(--color-dimgray-300);
}

.namejob {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}

.textTestimonial {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-size);
  line-height: 1.875rem;
  font-family: var(--text-single-100-bold);
  color: var(--color-gray-400);
  text-align: center;
}

.namejobParent {
  align-self: stretch;
  background-color: var(--base-white);
  height: 29.844rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--padding-xl) var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-mid-5);
  z-index: 0;
}

.logo5ff3c18e1Icon,
.profilPictureChild {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.profilPictureChild {
  width: 18.688rem;
  height: 25.781rem;
  z-index: 0;
}

.logo5ff3c18e1Icon {
  width: 6.194rem;
  height: 1.525rem;
}

.starsChild {
  width: 1.275rem;
  position: relative;
  height: 1.275rem;
}

.stars,
.starsTestimonialLogo {
  display: flex;
  align-items: center;
}

.stars {
  filter: drop-shadow(0 34.2px 72.74px rgba(21, 21, 21, 0.15));
  flex-direction: row;
  justify-content: flex-start;
}

.starsTestimonialLogo {
  width: 18.563rem;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  z-index: 1;
}

.frameContainer,
.frameGroup,
.frameParent,
.profilPicture {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.profilPicture {
  margin: 0 !important;
  top: 0;
  left: calc(50% - 149px);
  display: flex;
  gap: var(--gap-8xs);
  z-index: 1;
}

.frameContainer,
.frameGroup,
.frameParent {
  top: 127.75rem;
  left: 98.375rem;
  box-shadow: 20px 24px 48px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  width: 18.625rem;
  overflow: hidden;
  display: none;
}

.frameContainer,
.frameGroup {
  left: 118.625rem;
}

.frameContainer {
  left: 138.875rem;
}

.container {
  position: absolute;
  top: -24.494rem;
  left: 17.563rem;
  background-color: var(--base-white);
  width: 90rem;
  height: 7.369rem;
}

.company,
.customer {
  position: absolute;
  top: 7.5rem;
  left: 32.563rem;
  font-size: var(--body-default-size);
  line-height: 1.5rem;
  font-weight: 600;
}

.customer {
  left: 46.813rem;
}

.enterYourEmail,
.subscribeToNewsletter {
  position: absolute;
  left: 60.5rem;
  line-height: 1.5rem;
}

.subscribeToNewsletter {
  top: 7.5rem;
  font-size: var(--body-default-size);
  font-weight: 600;
}

.enterYourEmail {
  top: 11.188rem;
  display: inline-block;
  width: 16.875rem;
}

.aboutCompany,
.companyServices {
  position: absolute;
  left: 0;
  line-height: 1.25rem;
}

.aboutCompany {
  top: 0;
}

.companyServices {
  top: 2.188rem;
}

.contactUs,
.creativePeople,
.jobOpportunities {
  position: absolute;
  top: 4.375rem;
  left: 0;
  line-height: 1.25rem;
}

.contactUs,
.creativePeople {
  top: 6.563rem;
}

.contactUs {
  top: 8.75rem;
}

.links,
.links1 {
  position: absolute;
  top: 11.188rem;
  left: 32.563rem;
  width: 8.188rem;
  height: 10rem;
}

.links1 {
  left: 46.813rem;
  width: 6.75rem;
}

.inputChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-darkgray-100);
  box-sizing: border-box;
  width: 16.875rem;
  height: 3rem;
}

.enterYourEmail1 {
  position: absolute;
  top: 0.688rem;
  left: 1.25rem;
  line-height: 1.625rem;
}

.input {
  top: 0;
  left: 0;
  width: 16.875rem;
}

.buttonIcon,
.input,
.newsletterForm {
  position: absolute;
  height: 3rem;
}

.buttonIcon {
  top: 0;
  left: 13.875rem;
  width: 3rem;
}

.newsletterForm {
  top: 15.563rem;
  left: 60.5rem;
  width: 16.875rem;
  font-size: var(--font-size-sm);
}

.copyright2023 {
  position: absolute;
  top: 19.813rem;
  left: 60.5rem;
  line-height: 1.5rem;
}

.loremIpsumDolor {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.625rem;
  display: inline-block;
  width: 16.875rem;
}

.facebookIcon,
.instagramIcon,
.linkedinIcon,
.twitterIcon {
  position: absolute;
  height: 95%;
  width: 12.17%;
  top: 5%;
  right: 0.21%;
  bottom: 0;
  left: 87.62%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.facebookIcon,
.instagramIcon,
.twitterIcon {
  height: 93.33%;
  width: 12.03%;
  right: 31.33%;
  bottom: 1.67%;
  left: 56.64%;
}

.facebookIcon,
.twitterIcon {
  height: 73.89%;
  width: 12.17%;
  top: 10.56%;
  right: 62.31%;
  bottom: 15.56%;
  left: 25.52%;
}

.facebookIcon {
  height: 95%;
  width: 6.78%;
  top: 0;
  right: 93.43%;
  bottom: 5%;
  left: -0.21%;
}

.socialLinks {
  position: absolute;
  top: 8.25rem;
  left: 0.25rem;
  width: 8.938rem;
  height: 1.125rem;
}

.loremIpsumDolorAmetConsectParent {
  position: absolute;
  top: 11.313rem;
  left: 11.581rem;
  width: 16.875rem;
  height: 9.375rem;
}

.logoLogoworks1Icon {
  position: relative;
  /* top: 7.875rem;
  left: 11.813rem; */
  width: 9.75rem;
  height: 1.125rem;
  overflow: hidden;
}

.footerStyle4 {
  position: absolute;
  bottom: 0.188rem;
  left: calc(50% - 720px);
  background-color: var(--charcoal-black);
  width: 90rem;
  height: 26.375rem;
  overflow: hidden;
  font-size: var(--font-size-mini);
  color: var(--color-darkgray-100);
  font-family: var(--font-inter);
}

.ellipse15Stroke {
  width: 2.25rem;
  position: relative;
  height: 2.25rem;
  z-index: 0;
}

.arrow1Stroke {
  width: 30.56%;
  position: absolute;
  margin: 0 !important;
  height: 52.22%;
  top: 25%;
  right: 34.72%;
  bottom: 22.78%;
  left: 34.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}

.ellipse15StrokeParent {
  position: absolute;
  top: 43.375rem;
  left: 45rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs-5);
}

.groupChild {
  position: relative;
  /* top: 0;
  left: 31.188rem; */

  width: 34.625rem;
  height: 25.13113rem;
  overflow: hidden;
  margin-left: -3rem;
}

.buttonText {
  position: relative;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 112.5% */
  text-transform: capitalize;
  z-index: 0;
  font-family: 'Gilroy-SemiBold', sans-serif;
}

.inputFieldChild {
  width: 99.89%;
  position: absolute;
  margin: 0 !important;
  /* right: 0.11%;
  bottom: -0.062rem;
  left: 0; */
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.inputField {
  position: relative;
  /* top: 21.938rem;
  left: 0.063rem; */
  width: 27.688rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}

.span {
  color: #D93870;
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: Gilroy-Black; */
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.125rem;
}

.createAProfessionalLogoIn {
  margin: 0;
  color: #141414;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'gilroyblack';
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.125rem;
  /* 119.048% */
}

.createAProfessionalContainer {
  position: relative;
  color: #141414;
  width: 29.1875rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.125rem;
  /* 119.048% */

}

.paragraph1 {
  color: #000;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: center;
  position: relative;
  margin: auto;
  width: 26rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.5rem;

}

.customOption {
  background-color: transparent;
  color: black;
  padding: 8px;
}

.customOption:hover {
  background-color: transparent !important;
}

select option:hover {
  background-color: transparent !important;
}

.paragraph {
  /* top: 13rem;
  left: 0; */
  display: inline-block;
  width: 30.75rem;
  padding-top: 0.88rem;
  position: relative;
  line-height: 1.875rem;
  color: var(--color-black);
  text-align: left;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  /* 187.5% */
}

.paragraph1 {
  /* top: 32.375rem;
  left: 8.875rem; */
  font-size: var(--font-size-sm);
}

.buttonText1 {
  position: relative;
  color: var(--Neutral-Colors-White, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Gilroy-SemiBold;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 112.5% */
  text-transform: capitalize;
}

.masterPrimaryButton {
  position: relative;

  border-radius: var(--br-5xs);
  background-image: linear-gradient(87.8deg, #9300b8, #cb0064, #cb0064, #9300b8);
  /* width: 27.688rem; */
  width: 100%;
  height: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-17xl);
  box-sizing: border-box;
  color: var(--base-white);
  font-family: var(--font-gilroy-semibold);
  border: none;
  margin-top: 2rem;
  background-size: 300%;
  background-position: left;
  transition: 500ms background-position ease-in-out;
}



.masterPrimaryButton:hover {
  /* background-image: linear-gradient(87.8deg, #cb0064, #9300b8 58.4%);  */
  background-position: right;


}

/* @keyframes gradientTransition {
  from {
    background: linear-gradient(87.8deg, #9300b8, #cb0064 85.4%);
  }
  to {
    background: linear-gradient(87.8deg, #cb0064, #9300b8 85.4%);
  }
}

.masterPrimaryButton:hover {
  animation: gradientTransition 1s ease; 
}
  */
/* 
.buttonText {
  transition: transform 0.5s ease; 
} */

/* .masterPrimaryButton:hover .buttonText {
  transform: scale(1.1); 
} */


.lineIcon {
  width: 0.794rem;
  position: relative;
  height: 0.394rem;
  object-fit: contain;
}

.masterPrimaryButton1 {
  width: 27.688rem;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xl) var(--padding-17xl);
  box-sizing: border-box;
}

.buttonSetChild {
  width: 27.656rem;
  position: relative;
  max-height: 100%;
}

.buttonSet,
.industryDropDown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.industryDropDown {
  position: absolute;
  top: 16.75rem;
  left: 0.063rem;
}

.instanceParent {
  position: relative;
  /* top: 7.188rem;
  left: 8.313rem; */
  width: 76.625rem;
  height: 34.25rem;
  text-align: center;
  font-size: var(--text-single-100-bold-size);
  color: var(--color-dimgray-400);
  font-family: var(--paragraph-semibold-body-16);
}

.buttonText3 {
  position: relative;
  line-height: 115%;
  z-index: 0;
}

.masterPrimaryButtonChild {
  width: 1.313rem;
  position: absolute;
  margin: 0 !important;
  top: 2.625rem;
  left: calc(50% - 11px);
  border-radius: 50%;
  background: linear-gradient(87.8deg, #9300b8, #cb0064 58.4%);
  height: 1.25rem;
  z-index: 1;
}

.masterPrimaryButton2 {
  position: absolute;
  top: 0;
  left: 64.688rem;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-mediumvioletred);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  gap: var(--gap-8xs);
  color: var(--color-black);
  font-family: var(--font-gilroy-semibold);
}

.logoMaker {
  position: absolute;
  top: 0.231rem;
  left: 2.938rem;
  line-height: 1.131rem;
}

.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.55rem;
  height: 2.55rem;
}

.logoMakerParent {
  top: 0.063rem;
  left: 0;
  width: 8.5rem;
  height: 2.55rem;
  text-align: left;
  font-size: var(--font-size-lg-1);
  font-family: var(--font-syncopate);
}

.home,
.home1 {
  position: absolute;
}

.home1 {
  width: 100%;
  top: 0;
  left: 0;
  line-height: 115%;
  display: inline-block;
}

.home {
  top: 0.75rem;
  left: 11.125rem;
  width: 3.063rem;
  height: 1.125rem;
}

.graphicDesignServices {
  position: relative;
  line-height: 115%;
}

.vectorIcon {
  width: 0.613rem;
  position: relative;
  height: 0.306rem;
}

.dropdownMenu {
  width: 13.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}

.dropdownMenuList,
.menuItem {
  flex-direction: column;
  align-items: flex-start;
}

.dropdownMenuList {
  width: 13.75rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  display: none;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  font-family: var(--font-gilroy-regular);
}

.menuItem {
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 332.5px);
  height: 9.5rem;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.portfolio1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 115%;
  text-transform: capitalize;
  display: inline-block;
}

.portfolio {
  position: absolute;
  left: 29.563rem;
  width: 4.313rem;
}

.aboutUs,
.faq,
.portfolio {
  top: 0.75rem;
  height: 1.125rem;
}

.aboutUs {
  position: absolute;
  left: 35.063rem;
  width: 4.75rem;
}

.faq {
  left: 41.125rem;
  width: 2.188rem;
}

.div,
.div1,
.faq {
  position: absolute;
}

.div1 {
  top: 0;
  left: 0;
  line-height: 115%;
}

.div {
  top: 0.813rem;
  left: 54.75rem;
  width: 5.125rem;
  height: 1rem;
  font-size: var(--font-size-sm);
}

.image40Icon {
  position: absolute;
  top: 0.688rem;
  left: 61.563rem;
  width: 1.25rem;
  height: 1.25rem;
  object-fit: cover;
}

.masterPrimaryButtonParent {
  position: absolute;
  top: 2.563rem;
  left: 8.375rem;
  width: 73.188rem;
  height: 10.25rem;
  text-align: center;
  font-size: var(--text-single-100-bold-size);
  color: var(--color-dimgray-200);
  font-family: var(--font-gilroy-bold);
}

.imagePlaceholderParent {
  width: 100%;
  position: relative;
  background-color: var(--base-white);
  /* height: 97.625rem; */
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-black);
  font-family: var(--font-manrope); */
}

.wrappingContainer {
  /* width:73.125rem; */
  height: auto;

}

/* @media (min-width: 1441px){
.wrappingContainer{
  padding-left: 4.7rem;
  margin:auto;
}
} */

@media (min-width: 1280px) and (max-width: 1440px) {
  .wrappingContainer {
    margin-left: 0 !important;
  }
}

.carouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  /* Ensure the Carousel is above the SVG */
}

@media (max-width: 768px) {
  .animationContainer {
    display: none;
  }

  .createAProfessionalContainer {
    width: 100%;
  }

  .createAProfessionalLogoIn {
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .heroLeftContainer{
padding-left:0 !important;
padding-right:0 !important;
width: 100% !important;
  }
  .paragraph{
     width: 100%; 
    color: #141414;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .paragraph1{
    width: fit-content !important;
  }
  .underlineInput {
    width: 100% !important;
  }
  .masterPrimaryButton{
    width: 100%;
  }
  /* .imagePlaceholderParent{
    width: 24rem;
  } */
  .wrappingContainer{
    width: 100%;
    padding-top: 3.33rem !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {

  .createAProfessionalContainer {
    width: 95%;
  }

}




@media (min-width: 1170px) {
  .wrappingContainer {
    width: 73.125rem;
    height: auto;

  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  .groupChild{
    width: 90%;
    height: auto;
  }
  .wrappingContainer{
    gap: 10% !important;
    width: 100%;
  }
   .imagePlaceholderParent{
    padding-left: 3rem;
   }
}

@media (min-width: 768px) and (max-width: 992px) {
  .imagePlaceholderParent{
    padding-left: 1rem;
   }
  .animationContainer{
   display: none;
  }
  .createAProfessionalContainer {
    width: 100%;
  }

  .createAProfessionalLogoIn {
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .heroLeftContainer{
padding-left:0 !important;
padding-right:0 !important;
width: 100% !important;
  }
  .paragraph{
     width: 100%; 
    color: #141414;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .paragraph1{
    width: fit-content !important;
  }
  .underlineInput {
    width: 100% !important;
  }
  .masterPrimaryButton{
    width: 100%;
  }
  /* .imagePlaceholderParent{
    width: 24rem;
  } */
  .wrappingContainer{
    width: 100%;
    padding-top: 3.33rem !important;
  }
}