
@font-face {
    font-family: 'gilroyblack';
    src:  url('./assets/fonts/Gilroy-Black.woff') format('woff'),   /* Modern Browsers */
    url('./assets/fonts/Gilroy-Black.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/Gilroy-Black.eot');/* IE9 Compat Modes */
 
}

@font-face {
    font-family: 'gilroybold';
    src: url('./assets/fonts/Gilroy-Bold.woff') format('woff'),   /* Modern Browsers */
    url('./assets/fonts/Gilroy-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/Gilroy-Bold.eot'); /* IE9 Compat Modes */

}


@font-face {
    font-family: 'gilroyExtraBold';
    src: url('./assets/fonts/Gilroy-Extrabold.woff') format('woff'),   /* Modern Browsers */
    url('./assets/fonts/Gilroy-Extrabold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/Gilroy-Extrabold.eot'); /* IE9 Compat Modes */

}
@font-face {
    font-family: 'gilroyMedium';
    src: url('./assets/fonts/Gilroy-Medium.woff') format('woff'),   /* Modern Browsers */
    url('./assets/fonts/Gilroy-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/Gilroy-Medium.eot'); /* IE9 Compat Modes */

}

@font-face {
    font-family: 'vinque';
    src: url('./assets/fonts/vinque\ rg.otf') format('opentype'), /* Safari, Android, iOS */
   

}

@font-face {
    font-family: 'beyondWonderLand';
    src: url('./assets/fonts/Beyond\ Wonderland.ttf') format('truetype'), /* Safari, Android, iOS */
   
}

@font-face {
    font-family: 'fonarto';
    src: url('./assets/fonts/Fonarto.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/Fonarto\ XT.ttf') format('truetype'), /* Safari, Android, iOS */
   
}


@font-face {
    font-family: 'angeloText';
    src: url('./assets/fonts/AngloText.ttf') format('truetype'), /* Safari, Android, iOS */
  
   
}

@font-face {
    font-family: 'Adine';
    src: url('./assets/fonts/Adine\ Kirnberg.ttf') format('truetype'), /* Safari, Android, iOS */
 
   
}

@font-face {
    font-family: 'remjoo';
    src: url('./assets/fonts/Remjoo.ttf') format('truetype'), /* Safari, Android, iOS */

}


@font-face {
    font-family: 'GreenFuz';
    src: url('./assets/fonts/Green\ Fuz.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Nautilus';
    src: url('./assets/fonts/Nautilus.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'OldwaysI';
    src: url('./assets/fonts/Oldways\ italic.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Oldwaysr';
    src: url('./assets/fonts/Oldways\ Regular.otf') format('opentype'), /* Safari, Android, iOS */

}




@font-face {
    font-family: 'Arnoia';
    src: url('./assets/fonts/Arnoia\ Display.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Bad';
    src: url('./assets/fonts/Bad\ Gong.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'blowbrush';
    src: url('./assets/fonts/blowbrush.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'DepotFilled';
    src: url('./assets/fonts/DepotFilled.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'DoubleFeature20';
    src: url('./assets/fonts/DoubleFeature20.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'EraserRegular';
    src: url('./assets/fonts/EraserRegular.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Fanfarrón';
    src: url('./assets/fonts/Fanfarrón.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Flaemische';
    src: url('./assets/fonts/Flaemische\ Kanzleischrift.ttf') format('truetype'), /* Safari, Android, iOS */

}@font-face {
    font-family: 'mexcellent';
    src: url('./assets/fonts/mexcellent\ 3d.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'ParkLaneNF';
    src: url('./assets/fonts/ParkLaneNF.ttf') format('truetype'), /* Safari, Android, iOS */

}




/* Initial fonts */

@font-face {
    font-family: 'Hokia';
    src: url('./assets/fonts/al-hokia-2024-06-19-17-53-42-utc/Hokia\ Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Avapore';
    src: url('./assets/fonts/avapore-modern-font-2023-11-27-05-28-27-utc/Avapore.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'BackWild';
    src: url('./assets/fonts/back-wild-fun-graffiti-font-2023-11-28-17-48-06-utc/Back\ Wild.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Befort';
    src: url('./assets/fonts/befort-futuristic-modern-display-sans-2024-07-10-21-10-39-utc/OTF/Befort.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Bladge';
    src: url('./assets/fonts/bladge-a-modern-blackletter-font-2024-06-28-17-09-59-utc/Bladge.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'BROTHER';
    src: url('./assets/fonts/brother-typeface-2023-11-27-04-51-09-utc/FILE/BROTHER/BROTHER-Bold.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Cambridge';
    src: url('./assets/fonts/cambridge-bold-decorative-gothic-font-2023-11-27-04-54-31-utc/Cambridge.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'ChalkZone';
    src: url('./assets/fonts/chalk-zone-a-natural-chalk-font-2023-11-27-05-34-21-utc/Chalk\ Zone.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'chimers';
    src: url('./assets/fonts/chimers-graffiti-y2k-display-font-2024-07-23-22-41-55-utc/OpenType-TT/chimers\ font\ by\ dansdesign\ studio.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Choret';
    src: url('./assets/fonts/choret-fudyng-bubble-typeface-2023-11-27-05-36-03-utc/3D/ChoretFudyng3D-3D.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Cropar';
    src: url('./assets/fonts/cropar-futuristic-font-2023-11-27-04-56-38-utc/Cropar.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Weird';
    src: url('./assets/fonts/cs-weird-display-font-2024-07-24-15-55-09-utc/CS\ Weird/CSWeird-Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'CSWilson';
    src: url('./assets/fonts/cs-wilson-pixel-craft-font-2024-07-24-15-54-09-utc/CS\ Wilson/CSWilson-Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'DopeDisplay';
    src: url('./assets/fonts/dope-unique-display-logo-typeface-2023-11-27-04-52-02-utc/Desktop\ Fonts/OTF/DopeDisplay-Black.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'DreamSparks';
    src: url('./assets/fonts/dream-sparks-bubble-display-font-2023-12-23-05-20-30-utc/Dream\ Sparks\ Font/OTF/DreamSparks-Shiny.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'DubaiCity';
    src: url('./assets/fonts/dubai-city-arabic-font-2024-01-22-18-48-26-utc/Dubai\ City.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Elmirad';
    src: url('./assets/fonts/elmirad-arabic-styles-font-2024-06-14-16-26-57-utc/Main\ File\ Elmirad\ Font/Elmirad.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'EndlessFusion';
    src: url('./assets/fonts/endless-fusion-3d-layered-graffiti-font-2024-07-30-20-25-44-utc/Endless\ Fusion\ -\ Outline.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'FaqroExtended';
    src: url('./assets/fonts/faqro-extended-italic-2024-06-28-17-26-04-utc/FaqroExtended-Italic.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Garde';
    src: url('./assets/fonts/garde-v2-2024-06-28-16-01-42-utc/Garde_v2/Garde.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Gebriel';
    src: url('./assets/fonts/gebriel-regista-futuristic-font-sport-font-2024-05-02-19-59-58-utc/Gebriel\ Regista.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Ghoip';
    src: url('./assets/fonts/ghoip-2024-06-07-20-15-02-utc/Main\ Files/Ghoip.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Goblock';
    src: url('./assets/fonts/goblock-heavy-font-2024-01-22-19-07-29-utc/OpenType-PS/Goblock.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Gebriel';
    src: url('./assets/fonts/gebriel-regista-futuristic-font-sport-font-2024-05-02-19-59-58-utc/Gebriel\ Regista.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Korosu';
    src: url('./assets/fonts/korosu-japanese-typeface-2024-07-23-23-30-08-utc/Korosu.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Krunc';
    src: url('./assets/fonts/krunc-bold-display-typeface-2024-06-28-18-18-17-utc/Krunc-Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'LeonSlab';
    src: url('./assets/fonts/leon-slab-fat-bold-slab-font-2024-06-05-23-27-59-utc/LeonSlab.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'LUSER';
    src: url('./assets/fonts/luser-graffiti-bold-2023-11-27-05-22-22-utc/fonts/LUSER.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'MAENGAME';
    src: url('./assets/fonts/maengame-display-font-2023-11-27-05-12-28-utc/MAENGAME/MAENGAME\ SLANT.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Magnode';
    src: url('./assets/fonts/magnode-modern-geometric-font-2024-07-26-16-45-04-utc/Magnode.240720-1214.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'MoonWalk';
    src: url('./assets/fonts/moon-walk-futuristic-modern-fonts-2023-11-27-05-06-44-utc/Moon\ Walk.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'MORTHIX';
    src: url('./assets/fonts/morthix-liquid-font-2023-11-27-05-04-38-utc/MORTHIX.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Gebriel';
    src: url('./assets/fonts/neigh-modern-luxury-logo-font-2024-04-04-19-37-58-utc/OTF/Neigh-ExtraBold.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Nevla';
    src: url('./assets/fonts/nevla-a-versatile-typeface-2024-07-30-20-23-43-utc/Main\ File\ Nevla\ Font/Nevla.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Nexusbold';
    src: url('./assets/fonts/nexusbold-modern-condensed-sans-2023-11-27-05-04-08-utc/Nexusbold-RoundedItalic.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'CFNotche';
    src: url('./assets/fonts/notche-futuristic-sans-2024-01-02-23-36-59-utc/OTF/CFNotche-Black.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Osake';
    src: url('./assets/fonts/osake-japanese-font-2023-11-27-04-48-48-utc/Osake\ -\ Japanese\ Font/OTF/Osake.otf') format('opentype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'PixelImpact';
    src: url('./assets/fonts/pixel-impact-8bit-font-2024-04-03-19-13-04-utc/Pixel\ Impact.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Proxemic';
    src: url('./assets/fonts/proxemic-advanced-logo-font-2023-11-27-05-24-58-utc/Proxemic.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Qmico';
    src: url('./assets/fonts/qmico-modern-minimal-art-deco-font-2024-05-09-19-01-25-utc/Qmico\ ttf.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Ragley';
    src: url('./assets/fonts/ragley-retro-fancy-font-2024-07-08-18-56-11-utc/Ragley.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Rambors';
    src: url('./assets/fonts/rambors-retro-font-2023-11-27-05-35-08-utc/Rambors.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Ransom';
    src: url('./assets/fonts/ransom-modern-technology-font-2023-11-27-05-26-17-utc/Ransom.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'RetroMonkey';
    src: url('./assets/fonts/retro-monkey-2023-12-20-17-59-01-utc/RetroMonkey/OpenType-TT/RetroMonkey-Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Rompax';
    src: url('./assets/fonts/rompax-artdeco-rustic-font-2024-06-13-20-08-32-utc/Rompax.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'Siegra';
    src: url('./assets/fonts/siegra-sporty-tech-font-2023-11-27-05-06-52-utc/Siegra.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'SpaceOdyssey';
    src: url('./assets/fonts/space-odyssey-modern-sans-2023-11-27-04-52-54-utc/SpaceOdyssey.ttf') format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'SPORTNEWS';
    src: url('./assets/fonts/sport-news-2023-11-27-05-10-47-utc/OpenType-PS/SPORTNEWS-Regular.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'SummerOf76';
    src: url('./assets/fonts/summer-0f-76-multi-line-font-2023-11-27-04-55-10-utc/new_files/SummerOf76-New-Solid.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Superline';
    src: url('./assets/fonts/superline-display-typeface-2023-11-27-05-34-16-utc/Superline-Line.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Voltstrom';
    src: url('./assets/fonts/voltstrom-2024-07-08-18-56-11-utc/creativemarket/Voltstrom\ Oblique/OTF/Voltstrom.otf') format('opentype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Waosh';
    src: url('./assets/fonts/waosh-comic-graffiti-font-2024-05-27-17-42-56-utc/Waosh.ttf') format('truetype'), /* Safari, Android, iOS */

}
@font-face {
    font-family: 'Zetan';
    src: url('./assets/fonts/zetan-2024-06-13-18-22-00-utc/Main\ Files/Zetan.ttf') format('truetype'), /* Safari, Android, iOS */

}








