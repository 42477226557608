.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
        padding: 0 12px;
        height: 32px;
        text-align: center;
        margin-right: 1rem;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: 5px;
        line-height: 1.43;
        min-width: 32px;
        color: #696969;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
        /* 200% */

        &.dots:hover {
            background-color: transparent;
            cursor: default;

        }

        &:hover {
            background-color: #CB0064;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white !important;
            border-radius: 5px;
        }

        &.selected {
            background-color: #CB0064;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white !important;
            border-radius: 5px;
        }

        .arrow {
            &::before {
                position: relative;
                /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
                content: '';
                /* By using an em scale, the arrows will size with the font */
                display: inline-block;
                width: 1rem;
                height: 1rem;
                border-right: 0.12em solid rgba(0, 0, 0, 0.87);
                border-top: 0.12em solid rgba(0, 0, 0, 0.87);
            }

            &.left {
                transform: rotate(-135deg) translate(-50%);
                
            }

            &.right {
                transform: rotate(45deg);
            }
        }

        &.disabled {
            pointer-events: none;

            .arrow::before {
                border-right: 0.12em solid rgba(105, 105, 105, 0.4);
                border-top: 0.12em solid rgba(105, 105, 105, 0.4);
            }

            &:hover {
                background-color: #CB0064;
                cursor: default;
            }
        }
    }
}

.arrowButton {
    border: none;
    background-color: transparent;
    color: #CB0064;
    cursor: pointer;
    font-size: 1rem;
    /* margin: 0 0.25rem; */
    margin: 0 !important;
    padding: 0 !important;
  }
  .disabled{
    color: rgba(105, 105, 105, 1);

    
  }

  .arrowsvgButton{
padding: 0 !important;

  }