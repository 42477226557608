@import '../stylesheet.css';
.container {
  padding: 20px;
}
.customSectionWrapper{
  width: 73.125rem !important;
  padding-top: 6.94rem !important;
  padding-bottom: 6.88rem !important;
}

h2 {
  font-family: "Gilroy-bold";
  font-size: 2.2rem; 
  line-height: 3.125rem; /* 50px */
  color: #141414;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem;
}

.why-card-img {
  margin-right: 15px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin: auto 1px;
}

.card-text {
  font-size: 14px;
}
.card-parent-custom{
  margin-left: 70px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
  }

  .card-title {
    font-size: 16px;
  }

  .card-text {
    font-size: 12px;
  }
  .card-parent-custom{
    margin-left: 2px;
  }
}
.dot {
  color: #d93870;
  font-family: "Gilroy-Bold";
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 43.75px;
  text-align: left;
}

/* services section  */
.border-right-service {
  border-right: 1px solid rgba(200, 0, 104, 0.15);
}

.border-right-service:last-child {
  border-right: 1px solid rgba(200, 0, 104, 0.15);
}

.service-card {
  position: relative;
}

.service-card:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 76px;
  border: 1px solid #c8006826;
}

@media (max-width: 768px) {
  .service-card:not(:last-child)::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .border-right-service {
    border-right: none;
    border-bottom: 1px solid rgba(200, 0, 104, 0.15);
  }

  .border-right-service:last-child {
    border-bottom: none;
  }
}

.custom-button {
  height: 18px;
  position: relative;
  top: 20px; 
  font-family: 'Gilroy-SemiBold',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #BE0077;
  border: none;
  background: none;
  cursor: pointer;


text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.125rem; /* 112.5% */
text-decoration-line: underline;
text-transform: capitalize;
}

.custom-button:focus {
  outline: none;
}

/* FAQ section  */

.gradient-text {
  background: linear-gradient(349.51deg, #cb0064 -6.45%, #9300b8 119.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}


.FAQWraper {
  width: 73.125rem;
  padding-top: 6.87rem;
  padding-bottom: 6.88rem;
}

.FAQQuestion{
  color: #141414 ;
  font-family: 'gilroyMedium' ;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paddingOpen{
  padding:22px 46px 23px 46px !important;
}

.paddingClose{
  padding:29px 46px 17px 46px !important;
}


@media (max-width:768px){
  .paddingOpen{
    padding:1.12rem 0.94rem 1.13rem 0.94rem !important;
    margin-bottom: 0.75rem;
    background-color: #FBF3FA !important;

  }
  
  .paddingClose{
    padding:1.09rem 0.94rem 1.09rem 0.94rem !important;
    margin-bottom: 0.75rem;
    background-color: #F8F8F8 !important;
  }
.customSectionWrapper{
  width: 100% !important;
  padding-top: 6.25rem !important;
  padding-bottom: 3.25rem !important;
} 
.responsiveGap{
  row-gap: 3.62rem !important;
  column-gap: 0 !important;
}
.FAQWraper{
  width:100% !important;
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.FAQQuestion{
  width: 95%;
  line-height: 1.5rem;
}
}

@media (max-width:576px){
  .service-card:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 76px;
    border: 1px solid #c8006826;
  }

  .service-card::before,
.service-card::after {
  content: '';
  position: absolute;
  background: rgba(200, 0, 104, 0.05);
}

.service-card:nth-child(2n)::before {
  /* Vertical border between columns */
  width: 1px;
  height: 140%;
  top: 0;
  right: 12.3rem; /* Adjust based on your gap */
}

.service-card:nth-child(n+1)::after {
  /* Horizontal border between rows */
  width: 200%;
  height: 1px;
  left: -6rem;
  top: -14rem; /* Adjust based on your gap */
}

  .FAQWraper .sectionHeading{
width: 102% !important;

  }
  .customSectionWrapper .cardText{
    text-align: left !important;
    width:100% !important;
  }
  /* .FAQWraper{
    width:20rem !important;} */
    .FAQQuestion{
      width: 95%;
      line-height: 1.5rem;
      }
}




@media (min-width: 992px) and (max-width: 1230px) {

  .responsiveGap{
    gap:1rem !important;
  }
  .FAQWraper{
    width:100% !important;
  }
  .servicesStepsWrapper{
    gap:1.35rem !important;
 
  }
  }
 


  @media (min-width: 768px) and (max-width: 992px) {
    .responsiveGap{
      gap:0 !important;
      row-gap: 4rem !important;
    }
    .service-card::after{
      display: none;
    }
    .FAQWraper{
      width:100% !important;
    }
    .servicesStepsWrapper{
      justify-content: center !important;
      padding-left: 3.8rem;
    }
    
  }
  @media(min-width: 1200px){
    .FAQBox{

      width: 64.8125rem;
      height: 8.0625rem;
    }
  }