
@import '../stylesheet.css';
.stepheading {
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #696969;
  padding: 0 !important;
   
    text-align: left;
    margin-bottom: 0.5rem;
 
}

.logoSelectionHeading {
  font-family: 'gilroybold' !important;
  font-size: 2.1875rem !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  color: #141414;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left;
}

.preferencestext {
  color: #141414;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0 !important;
  padding: 0 !important;
  text-align: left;
}

.bodySection{
  /* padding-left:9rem; */
  margin-bottom:7.44rem;
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width:73.125rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  width: 100%;
  padding: 10px;
  gap: 10px; /* Add space between buttons */
  /* padding-top: 2.5rem; */
  flex-direction: row;
  padding-top: 0 !important;
}
.headingContainer{
  padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    /* margin-bottom:3.12rem; */
}


/* Media queries for additional responsiveness */
@media (max-width: 768px) {
  .step-heading {
    font-size: 1.25rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
    width:100%;
  }

  .logo-selection-heading {
    font-size: 1.5rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
  }

  .preferences-text {
    font-size: 0.875rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
    width:100%;
  }
}

@media (max-width: 768px) {
  .bodySection{
    width:100% !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .logoSelectionHeading{
    color: #141414;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'gilroybold';
    font-size: 1.8125rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

.preferencestext{
  color: #141414;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 0.875rem !important;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.buttonContainer{
  justify-content: center;
}
  .step-heading {
    font-size: 1.25rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
  }

  .logo-selection-heading {
    font-size: 1.5rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
  }

  .preferences-text {
    font-size: 0.875rem; /* Smaller font size for mobile */
    text-align: center; /* Center text on mobile */
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .bodySection {
    padding-left: 1.3rem !important;
  }

}



@media (max-width: 575px) {
.headingContainer{
  width:80% !important;
}
  }


  @media (min-width: 768px) and (max-width: 992px) {
    .bodySection {
      width: 100% !important;
      gap: 0 !important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (min-width: 992px) and (max-width: 1230px) {
    .bodySection {
      width: 100% !important;
      gap: 0 !important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }