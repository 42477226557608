#textsvg{
  font-size: 56px !important; }

#path1 {
    transform-origin: center;
    animation: expand 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  #path2 {
    animation: move 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  #path3 {
    animation: moveSecond 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);


    animation-delay: 2s; 
  }
  
  @keyframes expand {
    0% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100px, 0); /* Adjust as per your desired movement */
    }
  }

  @keyframes moveSecond {
    0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(170px, 120px); /* Adjust as per your desired movement */
      }
    }

    .svg-container {
        position: relative;
        width: 411px; /* Adjust width as needed */
        height: 100%; /* Adjust height as needed */
        z-index: 1; /* Ensure the SVG is below the Carousel */
      }
