/* Wrapper styling */
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  user-select: none;
}

.customSelect {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100% !important;
  border: none !important;
  border-radius: 0.25rem;
  height: 100%;


}

.custom-select-trigger {
  position: relative;
  display: block;
  padding: 0px 12px;
  font-family: Poppins;
  color: black;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.875rem;
  /* background: white; */
  background-color: rgba(194, 194, 194, 0.001) !important;
  display: flex;
  justify-content: space-between;


  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
  border-bottom: 1px solid rgba(20, 20, 20, 0.60) !important;
  outline: none;
  box-shadow: none;
  background-color: rgba(194, 194, 194, 0.1) !important;
  /* Make sure the background is transparent */
  /* width: 27.5rem; */
  height: 3.92rem;
  max-height: 100%;
  color: black;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;

}

.custom-select-trigger.open {
  border-bottom: 1px solid #CB0064 !important;
  color: #CB0064;
}

.greyColor {
  color: #878787;
  opacity: 1;
}

.blackColor {
  color: black;
  opacity: 1;
}


.custom-options {
  position: relative;
  display: flex;
  background-color: white !important;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 99;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #434343;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  /* transition: 1s display ease-out; */
  /* transition: 500ms; */
  opacity: 0;
  /* max-height: 0; */


  transition: max-height 3s ease-out, opacity 3s ease-out;
}

.custom-options.open {
  /* Adjust height based on content */

  opacity: 1;
  /* max-height: 10rem; */
} 


.customSelect.open .icon2 {
  display: block;

}

.customSelect.open .icon {
  display: none;

}

.custom-option {
  padding: 10px 20px;
  cursor: pointer;
}

.custom-option:hover {
  color: #CB0064;
}

@keyframes openAnimation {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 200px;
    opacity: 1;
  }
}

.icon1 {
  width: 0.8125rem;
  height: 0.75rem;
  flex-shrink: 0;

}

.icon2 {
  width: 0.8125rem;
  height: 0.75rem;
  flex-shrink: 0;
  display: none;

}

@media (max-width: 768px) {
  .custom-select-trigger {
    width:100% !important;
  }

 }

 /* @media (max-width: 380px) {
  .custom-select-trigger {
    width:20rem;
  }

 } */